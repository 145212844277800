import { styled, Typography } from '@mui/material'

export const outerContainerSx = {
  minWidth: 0,
  flexShrink: 1,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: { xs: 0.5, sm: 1 }
}

/**
 * The inner container to wrap the elements in the user prompt buttons
 * (task select, other fields specific to the task, and the text in between).
 * We use a Typography component instead of a Box component to ensure that
 * the text is displayed with the correct font size. This is necessary because
 * we use intl.formatMessage to render all the elements, and the text part
 * is rendered as a string, not as a React component.
 */
export const InnerContainer = styled(Typography)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  gap: theme.spacing(1),
  alignItems: 'center'
}))

// Add a styled component for non-breaking punctuation
export const NoWrap = styled('span')(({ theme }) => ({
  whiteSpace: 'nowrap',
  display: 'inline-flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  alignItems: 'center'
}))
