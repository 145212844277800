import { useAuth0 } from '@auth0/auth0-react'
import Loading from 'components/progress/Loading'
import { useUserProfile } from 'features/users/api/useUserProfile'
import { type ReactNode, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { AuthContext, User } from '../context/AuthContext'

interface Props {
  children: ReactNode
}

/**
 * This component is used to wrap the app in a context provider that provides the current user,
 * which is the Auth0 user combined with the user profile from the API.
 */
const AuthProvider = ({ children }: Props): JSX.Element => {
  const { user: auth0User } = useAuth0()
  const { isLoading, data: userProfile } = useUserProfile()
  const [currentUser, setCurrentUser] = useState<User | null | undefined>(
    undefined
  )

  useEffect(() => {
    console.debug('> AuthProvider [isLoading, userProfile, auth0User]', isLoading, userProfile, auth0User)

    if (isLoading) {
      return
    }

    if (userProfile !== undefined && userProfile !== null && auth0User !== undefined) {
      setCurrentUser(new User(userProfile, auth0User))
    }
  }, [isLoading, userProfile, auth0User])

  if (isLoading) {
    return <Loading verticallyCentered />
  }

  if (auth0User === undefined) {
    console.debug('Not authenticated, redirect to /login')
    return <Navigate to="/login" />
  }

  if (currentUser === null) {
    console.debug('User profile not found, redirect to /callback')
    return <Navigate to="/callback" />
  }

  if (currentUser === undefined) {
    return <Loading verticallyCentered />
  }

  return (
    <AuthContext.Provider value={currentUser}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
