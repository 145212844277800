import type React from 'react'
import { useErrorModal } from '../../context/ErrorModalContext'
import { ErrorModal } from './ErrorModal'
import { useIntl } from 'react-intl'
import { useMemo } from 'react'

export const ErrorModalContainer: React.FC = (): JSX.Element => {
  const intl = useIntl()
  const { isOpen, hideError } = useErrorModal()

  const title = useMemo(() => intl.formatMessage({
    id: 'app.error-modal.title',
    defaultMessage: 'Something went wrong'
  }), [intl])

  const paragraph1 = useMemo(() => intl.formatMessage({
    id: 'app.error-modal.body.paragraph1',
    defaultMessage: 'We apologize for any inconvenience, but it seems there is a temporary technical issue on our end. Please try again later.'
  }), [intl])

  const paragraph2 = useMemo(() => intl.formatMessage({
    id: 'app.error-modal.body.paragraph2',
    defaultMessage: 'Our team has been notified of the issue and will work to resolve it as soon as possible.'
  }), [intl])

  return (
    <ErrorModal
      open={isOpen}
      onClose={hideError}
      title={title}
    >
      <p>
        {paragraph1}
      </p>
      <p>
        {paragraph2}
      </p>
    </ErrorModal>
  )
}

export default ErrorModalContainer
