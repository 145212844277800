import { type ChipProps, Box, Chip, Link, Paper, Typography, styled } from '@mui/material'

export const StyledLink = styled(Link)(({ theme }) => ({
  // Don't show underline
  textDecoration: 'none',
  // Allow text selection
  userSelect: 'text',
  // But still handle clicks
  '&:hover': {
    cursor: 'pointer'
  },
  // This ensures the text is selectable as part of a paragraph
  display: 'inline-flex',
  // Prevent selection from being interrupted
  pointerEvents: 'auto',
  // Fix vertical alignment with text
  verticalAlign: 'baseline'
}))

interface StyledLinkChipProps extends ChipProps {
  clickable?: boolean
}

export const StyledLinkChip = styled(Chip)<StyledLinkChipProps>(({ theme, clickable }) => ({
  // Allow text selection
  userSelect: 'text',
  // Make chip behave more like inline text
  height: 'auto',
  // Allow shrinking below content size
  minWidth: 0,
  flex: '0 1 auto',
  // Set a transparent border by default, to reserve space for the hover effect
  border: '1px solid transparent',
  // Highlight the border of the chip when labeled hovered and chip is clickable
  '&:hover': {
    borderColor: theme.palette.primary.main
  },
  // Transition the border color when hovered
  transition: 'border-color 0.3s ease',
  // Fix vertical alignment with text
  verticalAlign: 'baseline',
  // Reduce padding to match text line height better
  padding: 0,
  // Make the label shrinkable, adding an ellipsis when it overflows
  '& .MuiChip-label': {
    // Allow text selection
    userSelect: 'text',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    // Reduce padding to match text line height better
    padding: '0px 8px',
    // Fix height to match text
    lineHeight: 'inherit'
  }
}))

export const LabelContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  // No gap since we're using absolute positioning for the icon
  gap: 0,
  // Ensure vertical alignment with surrounding text
  alignItems: 'center',
  maxWidth: '350px',
  // Needed for absolute positioning of the icon
  position: 'relative',
  // Remove any extra spacing
  margin: 0,
  padding: 0
}))

export const LinkTextTypography = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  // Fix vertical alignment with text
  display: 'inline',
  // Remove any margins that might affect alignment
  margin: 0,
  // Match line height with surrounding text
  lineHeight: 'inherit',
  // Ensure text is properly aligned
  verticalAlign: 'baseline'
}))

export const tooltipSx = {
  bgcolor: 'background.paper',
  color: 'text.primary',
  '& .MuiTooltip-arrow': {
    color: 'background.paper'
  },
  p: 0 // Remove default padding since we're using Paper
}

export const TooltipContentContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  maxWidth: 350,
  gap: theme.spacing(0.75),
  boxShadow: theme.shadows[3]
}))

export const HostnameTypography = styled(Typography)(({ theme }) => ({
  variant: 'caption'
}))

export const TitleTypography = styled(Typography)(({ theme }) => ({
  variant: 'subtitle2',
  fontWeight: 'bold'
}))

export const SnippetTypography = styled(Typography)(({ theme }) => ({
  variant: 'body2',
  color: theme.palette.text.secondary
}))
