import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { getUserProfile } from './getUserProfile'
import { type UserProfile } from 'features/users'

export function useUserProfile (): UseQueryResult<UserProfile | null, Error> {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()

  return useQuery({
    queryKey: ['userProfile'],
    queryFn: async (): Promise<UserProfile | null> => {
      if (!isAuthenticated) return null
      const token = await getAccessTokenSilently()
      return await getUserProfile(token)
    },
    // Only fetch the user profile if the user is authenticated
    enabled: isAuthenticated,
    staleTime: 5 * 60 * 1000 // 5 minutes
  })
}

export default useUserProfile
