import { Auth0Provider, type AppState, type AuthorizationParams } from '@auth0/auth0-react'
import { type FC, useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import getConfig from 'services/config'

interface Props {
  children: React.ReactNode
}

export const Auth0ProviderWithNavigate: FC<Props> = ({ children }): JSX.Element => {
  const navigate = useNavigate()

  // Get configuration values
  const domain = getConfig('AUTH0_DOMAIN')
  const clientId = getConfig('AUTH0_CLIENT_ID')
  const redirectUri = getConfig('AUTH0_CALLBACK_URL')
  const audience = getConfig('AUTH0_AUDIENCE_URL')

  // Validate required configuration
  if (domain === undefined || clientId === undefined || audience === undefined) {
    throw new Error('AUTH0_DOMAIN, AUTH0_CLIENT_ID and AUTH0_AUDIENCE_URL must be set in the runtime configuration (config.js)')
  }

  const onRedirectCallback = useCallback((appState?: AppState): void => {
    navigate(appState?.returnTo ?? window.location.pathname)
  }, [navigate])

  const authorizationParams = useMemo((): AuthorizationParams => ({
    ...(redirectUri !== undefined ? { redirect_uri: redirectUri } : {}),
    audience
  }), [redirectUri, audience])

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={authorizationParams}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
    >
      {children}
    </Auth0Provider>
  )
}
