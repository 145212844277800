import { type DocReviewIssue, SanityCheckOption } from 'features/analyze/types'
import { useIntl } from 'react-intl'
import { Typography } from '@mui/material'
import { useMemo } from 'react'
interface Props {
  result: DocReviewIssue
}

export const Title = ({ result }: Props): JSX.Element => {
  const intl = useIntl()

  // Issue type can be used as a fallback for the title in case the issue title or the defined term name is not defined
  const translatedIssueType = useMemo(() => intl.formatMessage({ id: `app.doc-review.sanity-check.options.${result.type}` }), [result.type, intl])
  const translatedIssueTitle = result.title ?? translatedIssueType

  const issueTypeCaption = <Typography variant='caption'>{translatedIssueType}</Typography>

  // In the case of defined terms, the title will instead be the term name and its nature,
  // e.g. "Goods -- Explicitly defined term"
  if (result.type === SanityCheckOption.DefinedTerms && result.termNature !== null) {
    const translatedTermNature = intl.formatMessage({ id: `app.doc-review.sanity-check.term-nature.${result.termNature}` })
    return <>
      {issueTypeCaption}
      <Typography>
        <b>{intl.formatMessage({ id: 'app.quoted-text' }, { text: result.term ?? translatedIssueTitle })}</b>
        {' '}&ndash;{' '}
        <i>{translatedTermNature}</i>
      </Typography>
    </>
  }

  // Use issue's title if it has one, and otherwise use the issue's type (which for now is always a sanity check option)
  return <>
    {issueTypeCaption}
    <Typography><b>{translatedIssueTitle}</b></Typography>
  </>
}

export default Title
