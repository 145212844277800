import Loading from 'components/progress/Loading'
import useSummarizeTaskParams from 'features/summarize/hooks/useSummarizeTaskParams'
import { type FC } from 'react'
import { SummarizeLengthInput } from './SummarizeLengthInput'

export const SummarizeLengthInputContainer: FC = (): JSX.Element => {
  const { summarizeTaskParams, updateSummarizeTaskParams } = useSummarizeTaskParams()

  if (summarizeTaskParams === null) {
    return <Loading />
  }

  const handleChange = (value: number | null): void => {
    updateSummarizeTaskParams({ length: value })
  }

  return (
    <SummarizeLengthInput
      value={summarizeTaskParams.length}
      onChange={handleChange}
    />
  )
}

export default SummarizeLengthInputContainer
