import { isValidAnalysisInput } from 'features/analyze/api/isValidAnalysisInput'
import { type AttachmentsAndUploads } from 'features/documents'
import { isValidDraftInput } from 'features/draft'
import { type InputValidationResult } from 'features/send-message'
import isValidSummarizeInput from 'features/summarize/api/isValidSummarizeInput'
import { type UserInputForm } from 'features/user-input-form'

/**
 * Check if the input is valid for sending a message.
 * Returns a tuple of [isValid, validationMessage]
 */
export const isValidInput = (
  input: string,
  userInputForm: Partial<UserInputForm>,
  attachments: AttachmentsAndUploads
): InputValidationResult => {
  // Not valid if an upload is in progress
  // This corresponds to our pending list, excluding the failed uploads.
  const pendingUploadsNotFailed = attachments.pending.filter((upload) => upload.status !== 'FAILURE')
  if (pendingUploadsNotFailed.length > 0) return [false, 'Wait for uploads to complete']

  // Input may be left blank if user has selected a task, and action and some options
  if (userInputForm.mainTask === undefined || userInputForm.mainTask === null) {
    return [false, 'Select a task']
  }

  if (userInputForm.mainTask === 'Analyze') {
    return isValidAnalysisInput(userInputForm, attachments)
  }

  if (userInputForm.mainTask === 'Draft') {
    return isValidDraftInput(input, userInputForm)
  }

  if (userInputForm.mainTask === 'Summarize') {
    return isValidSummarizeInput(input, userInputForm, attachments)
  }

  // For Translate, input is only required if no attachments are present
  if (userInputForm.mainTask === 'Translate') {
    if (input.trim().length === 0 && attachments.uploaded.length === 0) {
      return [false, 'Add a document or enter instructions']
    }
    return [true, '']
  }

  // For all other tasks, just having a non-empty text input is sufficient
  if (input.trim().length === 0) {
    // For Chat task, we also want to mention that user can select a task
    // if they don't want to enter instructions.
    if (userInputForm.mainTask === 'Chat') {
      return [false, 'Enter instructions or select a task']
    } else {
      return [false, 'Enter instructions']
    }
  } else {
    return [true, '']
  }
}
