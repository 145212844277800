import DiffViewer from 'components/diff-viewer'
import { type Correction } from 'features/analyze'
import { Box, Typography, useTheme } from '@mui/material'
import { useIntl } from 'react-intl'
import { useMemo } from 'react'

interface Props {
  corrections?: Correction[]
}

const CorrectionsDisplay = ({ corrections }: Props): JSX.Element => {
  const intl = useIntl()
  const theme = useTheme()

  const isSingleCorrection = corrections !== undefined && corrections !== null && corrections.length === 1

  const title = useMemo(() => intl.formatMessage({
    id: `app.doc-review.suggested-corrections.title.${isSingleCorrection ? 'singular' : 'plural'}`
  }), [intl, isSingleCorrection])

  if (corrections === undefined || corrections === null || corrections.length === 0) {
    return <></>
  }

  return (
    <Box className='corrections-section' sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1)
    }}>
      {isSingleCorrection
        ? (
          // FIXME: Show only the corrected text for now, because the before and after text
          // are often not "aligned", e.g., before text will be a full sentence and after text
          // only a single word to be corrected. There, we can use DiffViewer show the difference,
          // and simply show the corrected text for now.
          <Typography>
            <b>{title}</b> {corrections[0].after}
          </Typography>
          // <DiffViewer
          //   key={idx}
          //   oldText={correction.before}
          //   newText={correction.after}
          // />
          )
        : (
          <>
            <Typography>
              <b>{title}</b>
            </Typography>
            <ul>
              {corrections.map((correction, idx) => (
                <li key={idx}>
                  <Typography>
                    {correction.after}
                  </Typography>
                </li>
              ))}
            </ul>
          </>
          )}
    </Box>
  )
}

export default CorrectionsDisplay
