import { Box, Divider, useTheme } from '@mui/material'
import { useSidepanes } from 'components/sidepanes'
import DocReviewResultsDisplay from 'features/analyze/components/DocReviewResultsDisplay'
import { ExportModifiedDocButton } from 'features/export-document'
import { Role, type Message } from 'features/messages/types'
import UserAvatar from 'features/users/components/UserAvatar'
import { useEffect, useRef, useState } from 'react'
import { CopyToClipboardButton } from 'components/buttons/CopyToClipboardButton'
import { ThumbsDownButton, ThumbsUpButton } from 'components/buttons/ThumbsButtons'
import AIAvatar from 'components/central-pane/chat/AIAvatar'
import { AvatarContainer, BottomSection, FeedbackButtons, MessageContentContainer, TopSection, containerSx } from 'components/central-pane/chat/MessageBox.styles'
import MessageText from 'components/central-pane/chat/MessageText'
import ToggleRawButton from 'components/central-pane/chat/ToggleRawButton'
import ToggleStateButton from 'components/central-pane/chat/ToggleStateButton'
import UserInputFormDigest from 'components/central-pane/chat/UserInputFormDigest'
import getConfig from 'services/config'

interface MessageBoxProps {
  msg: Message
  disableButtons?: boolean
  onFeedbackSubmitted?: (feedback: number) => void
  onCorrectionSubmitted?: (correction: string) => void
  sessionId: string
}

/**
 * Container for a message in the chat, which includes things like the message text,
 * but also buttons for feedback, correction, etc.
 */
export const MessageBox: React.FC<MessageBoxProps> = ({
  msg, disableButtons, onFeedbackSubmitted, onCorrectionSubmitted, sessionId
}: MessageBoxProps) => {
  const theme = useTheme()
  const { centralPane } = useSidepanes()
  const ref = useRef<HTMLDivElement>(null)

  const [showRaw, setShowRaw] = useState<boolean>(false)
  const [showState, setShowState] = useState<boolean>(false)
  const [feedback, setFeedback] = useState<number>(msg.feedback ?? 0)

  useEffect(() => {
    if (ref.current != null) {
      ref.current.scrollIntoView({ behavior: 'auto', block: 'start' })
    }
  }, [])

  useEffect(() => {
    setFeedback(msg.feedback ?? 0)
  }, [msg])

  const handleCopyToClipboard = (): void => {
    void (async function () {
      await navigator.clipboard.writeText(msg.cleartextContent)
    })()
  }

  const handleThumbsUp = (): void => {
    if (onFeedbackSubmitted === undefined) throw new Error('onFeedbackSubmitted is undefined')
    const newFeedback = feedback === 1 ? 0 : 1
    // Update local state for immediate feedback
    setFeedback(newFeedback)
    // Callback for API call
    onFeedbackSubmitted(newFeedback)
  }

  const handleThumbsDown = (): void => {
    if (onFeedbackSubmitted === undefined) throw new Error('onFeedbackSubmitted is undefined')
    const newFeedback = feedback === -1 ? 0 : -1
    // Update local state for immediate feedback
    setFeedback(newFeedback)
    // Callback for API call
    onFeedbackSubmitted(newFeedback)
  }

  const handleCorrectionSubmit = (correction: string): void => {
    if (onCorrectionSubmitted === undefined) throw new Error('onCorrectionSubmitted is undefined')
    onCorrectionSubmitted(correction)
  }

  /**
   * Generate the content to display for the message.
   * This can be a string of Markdown text, or a JSX element when there is a custom visualization
   * for the agent's internal state (e.g., results of a comparative analysis).
   * In both case, we used cleartext (de-anonymized) parts of the message to generate this content,
   * which is what the user wants to see.
   */
  const generateContent = (): string | JSX.Element => {
    // By default, the content to display is the cleartext content of the message,
    // which is some Markdown text that we will render.
    const textContent = msg.cleartextContent.trim()

    // If the message is from the user, always show the text content (no special visualization)
    if (msg.role === Role.USER) {
      return textContent
    }

    // Check consistency of message state with its de-anonymized version:
    // We expect the de-anonymized state to be defined if the state is defined,
    // and vice versa. If this is not the case, we display an error message.
    // This already happened that we forgot to provide the de-anonymized state
    // in some cases, hence this check.
    if (
      msg.deanonState !== undefined && msg.deanonState !== null &&
      (msg.state === undefined || msg.state === null)
    ) {
      console.error('Message state is present but de-anonymized state is missing! ', msg)
    }

    // For debugging purposes, display the JSON state of the message
    if (showState) {
      return (msg.deanonState !== undefined && msg.deanonState !== null)
        ? '```json\n' + JSON.stringify(msg.deanonState, null, '  ') + '\n```'
        : 'no state'
    }

    // If the message state contains doc review results,
    // we use a custom visualization to display them.
    // Also make sure that the current task is 'Analyze',
    // because the doc review could be from a previous message
    // (we don't remove the docReviewResults from the state
    // after each message).
    // FIXME: Consider removing the docReviewResults from the state
    // after each message, to avoid possible confusion.
    const docReviewResults = msg.deanonState?.docReviewResults ?? null
    const userInputForm = msg.deanonState?.userInputForm ?? null
    if (
      docReviewResults !== null &&
      userInputForm !== null &&
      userInputForm.mainTask === 'Analyze'
    ) {
      return (
        <DocReviewResultsDisplay
          results={docReviewResults}
          userInputForm={userInputForm}
          sessionId={sessionId}
          messageIdx={msg.idx ?? 0}
        />
      )
    }

    return textContent
  }
  const content = generateContent()

  return (
    <Box className='message-box' ref={ref} sx={containerSx(msg, theme.palette, centralPane.width)}>
      {
        msg.role === Role.ASSISTANT &&
        <AvatarContainer>
          <AIAvatar settings={msg.settings} />
        </AvatarContainer>
      }
      {
        msg.role === Role.USER && (
          <AvatarContainer>
            <UserAvatar sx={{ scale: 0.65 }} />
          </AvatarContainer>
        )
      }

      {/* Message content (text input and user input form, if any) */}
      <MessageContentContainer>
        {
          msg.role === Role.USER && msg.state?.userInputForm !== undefined && (
            <TopSection>
              <UserInputFormDigest userInputForm={msg.state?.userInputForm} />
              <Divider />
            </TopSection>
          )
        }

        {
          // Render the content of the message
          typeof content === 'string'
            ? <MessageText
              content={content}
              sources={msg.state?.sources}
              showRaw={showRaw}
            />
            : content
        }

        {
          // For draft messages, we show the message text and
          // add a button to export the modified document below
          msg.role === 'assistant' && msg.state?.userInputForm?.mainTask === 'Draft' && (
            <ExportModifiedDocButton
              sessionId={sessionId}
              messageIdx={msg.idx ?? 0}
            />
          )
        }

        {
          msg.role === 'assistant' && disableButtons !== true && (
            <BottomSection>
              <Divider />
              <FeedbackButtons>
                <ThumbsUpButton feedback={feedback} onClick={handleThumbsUp} />
                <ThumbsDownButton feedback={feedback} onClick={handleThumbsDown} />
                <CopyToClipboardButton onClick={handleCopyToClipboard} />
                {
                  getConfig('COPILEX_DEV_MODE') === 'true' &&
                  <ToggleRawButton showRaw={showRaw} setShowRaw={setShowRaw} />
                }
                {
                  getConfig('COPILEX_DEV_MODE') === 'true' &&
                  <ToggleStateButton showState={showState} setShowState={setShowState} />
                }
              </FeedbackButtons>
            </BottomSection>
          )
        }

      </MessageContentContainer>
    </Box>
  )
}
