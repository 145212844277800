import { type User } from 'context/AuthContext'
import { axiosInstance, createAuthHeaderFromToken, defaultTTL } from 'services/axios'
import { type UserSettings } from '../types'
import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { useAuth } from 'context/AuthContext'
import { queryClient } from 'services/react-query'
import { useUpdateSession } from 'features/sessions/api/updateSession'

export const updateUserSettings = async (
  token: string,
  user: User,
  update: Partial<UserSettings>
): Promise<void> => {
  await axiosInstance.patch(
    '/users/me/settings',
    {
      assistantId: update.assistantId,
      confidentialityLevel: update.confidentialityLevel?.level,
      anonymizationSettings: update.anonymizationSettings
    },
    {
      headers: createAuthHeaderFromToken(token),
      cache: {
        update: {
          'get-user-settings': 'delete'
        },
        ttl: defaultTTL
      }
    }
  )
}

interface UpdateUserSettingsVariables {
  sessionId?: string
  update: Partial<UserSettings>
}

interface UpdateUserSettingsContext {
  previousSettings: UserSettings | null
}

/**
 * Mutation to update the user settings.
 */
export const useUpdateUserSettings = (): UseMutationResult<void, Error, UpdateUserSettingsVariables, UpdateUserSettingsContext> => {
  const currentUser = useAuth()
  const { getAccessTokenSilently } = useAuth0()
  const { mutate: updateSession } = useUpdateSession()

  const mutationFn = async ({ update, sessionId }: UpdateUserSettingsVariables): Promise<void> => {
    const token = await getAccessTokenSilently()

    // If a session ID is provided, update the session settings,
    // which will be used over the user settings for assistant and
    // confidentiality level.
    if (sessionId !== undefined) {
      const sessionUpdate = {
        settings: {
          assistantId: update.assistantId,
          confidentialityLevel: update.confidentialityLevel?.level
        }
      }
      updateSession({ sessionId, update: sessionUpdate })
    }

    // Call the API to update the user settings
    await updateUserSettings(token, currentUser, update)
  }

  return useMutation({
    mutationKey: ['updateUserSettings'],
    mutationFn,
    onMutate: async ({ update }: UpdateUserSettingsVariables): Promise<UpdateUserSettingsContext> => {
      // Cancel any outgoing refetch
      await queryClient.cancelQueries({ queryKey: ['userSettings'], exact: true })

      // Snapshot the previous value
      const previousSettings = queryClient.getQueryData<UserSettings>(['userSettings'])

      // Optimistically update to the new value
      queryClient.setQueryData(['userSettings'], update)

      console.debug('Update userSettings:', update)

      return { previousSettings: previousSettings ?? null }
    },
    onError: (_err, _variables, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      if (context?.previousSettings !== null && context?.previousSettings !== undefined) {
        queryClient.setQueryData(['userSettings'], context.previousSettings)
      }
      console.error('Failed to update user settings:', _err)
    },
    onSettled: () => {
      // Always refetch after error or success to ensure cache is in sync with server
      void queryClient.invalidateQueries({ queryKey: ['userSettings'] })
    }
  })
}
