import { type FC } from 'react'
import { Box, CircularProgress } from '@mui/material'

interface LoadingProps {
  size?: string | number | undefined
  verticallyCentered?: boolean
}

const Loading: FC<LoadingProps> = ({ size = undefined, verticallyCentered = false }: LoadingProps): JSX.Element => (
  <Box
    className="loading-container"
    display="flex"
    justifyContent="center"
    alignItems="center"
    width="100%"
    height="100%"
    sx={verticallyCentered
      ? {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        }
      : {}}
  >
    <CircularProgress sx={{ ml: 'auto', mr: 'auto' }} size={size} />
  </Box>
)

export default Loading
