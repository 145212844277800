import { useGlobals } from 'context/GlobalsContext'
import { type ComparativeAnalysisOption, type ComparisonTypeId } from 'features/comparative-analysis'
import getSelectedComparativeAnalysisOptions from 'features/comparative-analysis/api/getComparativeAnalysisOptions'
import { type DocType } from 'features/documents'
import { useUserInputForm } from 'features/user-input-form/hooks/useUserInputForm'
import { AnalysisTaskAction, defaultMainTask, type AnalysisTaskParams, type UserInputForm } from 'features/user-input-form/types'
import { type MenuItemData } from 'mui-nested-menu'
import { useIntl } from 'react-intl'
import TASKS, { analyzeTaskActionToIcon, briefAnalysisIcon, comparativeAnalysisIcon, contractAnalysisIcon, dataRoomIcon, docTypeToDraftIcon, draftBasedOnCustomInstructionsIcon, draftBasedOnTemplateIcon, versionCompareIcon, type TaskEntry } from './tasks'
import getConfig from 'services/config'

interface UseSelectTaskEntryResult {
  /**
   * Selected main task with its information
   */
  selectedTaskEntry: TaskEntry
  /**
   * Nested menu items data for the main task, to be displayed in the task select menu
   */
  menuItemsData: MenuItemData
  /**
   * Get the menu item data for a main task, given its label.
   */
  getMenuItemDataForMainTaskLabel: (label: string) => MenuItemData
}

/**
 * Hook to select a task entry, handling the update of the user input form.
 */
export const useSelectTaskEntry = (): UseSelectTaskEntryResult => {
  const intl = useIntl()
  const globals = useGlobals()
  const { userInputForm, setUserInputForm } = useUserInputForm()

  const getSelectedTaskEntry = (userInputForm: Partial<UserInputForm>): TaskEntry => {
    // If the main task is not set, use the default main task
    const taskEntry = TASKS.find((task) => {
      const { mainTask: mainTaskOrNullOrUndefined } = userInputForm
      const mainTask = (
        mainTaskOrNullOrUndefined === null || mainTaskOrNullOrUndefined === undefined
          ? defaultMainTask
          : mainTaskOrNullOrUndefined
      )
      return task.userInputForm.mainTask === mainTask
    })

    if (taskEntry === undefined) {
      throw new Error(`Task with mainTask ${userInputForm.mainTask ?? '(undefined)'} not found`)
    }

    return taskEntry
  }

  const selectedTaskEntry = getSelectedTaskEntry(userInputForm)
  const mainTask = selectedTaskEntry.userInputForm.mainTask
  if (mainTask === undefined || mainTask === null) {
    throw new Error('Main task is undefined')
  }

  const translateMainTaskLabel = (label: string): string => {
    return intl.formatMessage({
      id: `app.main-task.${label}`,
      defaultMessage: label
    })
  }

  /**
   * Get the menu item data for a main task, given its label.
   * It includes a callback to update the user input form if the task is selected,
   * hence the presence of this function in the useSelectTaskEntry hook.
   */
  const getMenuItemDataForMainTaskLabel = (label: string): MenuItemData => {
    const selectedTaskEntry = TASKS.find((task) => task.label === label)
    if (selectedTaskEntry === undefined) {
      throw new Error(`Task with label ${label} not found`)
    }
    // Get the sub-menu items for the selected task entry, if any
    // analyzeMenuItemsData, draftMenuItemsData, ...
    const subMenuItems = getSubMenuItems(label)

    return {
      label: translateMainTaskLabel(label),
      leftIcon: selectedTaskEntry.icon,
      callback: () => {
        setUserInputForm(prevForm => ({
          ...prevForm,
          ...selectedTaskEntry.userInputForm
        }))
      },
      disabled: selectedTaskEntry.disabled ?? false,
      items: subMenuItems
    }
  }

  const getSubMenuItems = (label: string): MenuItemData[] => {
    switch (label) {
      case 'Analyze':
        return analyzeMenuItemsData
      case 'Draft':
        return draftMenuItemsData
      default:
        return []
    }
  }

  const selectedTaskLabel = translateMainTaskLabel(selectedTaskEntry.label)

  const comparisonTypes = globals.taskParams.analyze.comparativeAnalysis.comparisonTypes

  const updateAnalysisTaskParams = (taskParams: AnalysisTaskParams, clearTextInput: boolean = false): void => {
    setUserInputForm(prevForm => ({
      ...prevForm,
      mainTask: 'Analyze',
      taskParams: {
        ...prevForm.taskParams,
        ...taskParams
      },
      // For some sub-tasks, the text input is not supported, so we must clear it
      ...(clearTextInput ? { textInput: '' } : {})
    }))
  }

  const setComparativeType = (comparisonTypeId: ComparisonTypeId | null): void => {
    const options: ComparativeAnalysisOption = getSelectedComparativeAnalysisOptions(userInputForm)
    // Comparative analysis does not support a text input, so we must clear it
    const clearTextInput = true
    updateAnalysisTaskParams({
      action: AnalysisTaskAction.ComparativeAnalysis,
      comparativeAnalysisOptions: {
        ...options,
        comparisonTypeId
      }
    }, clearTextInput)
  }

  const comparativeAnalysisItems: MenuItemData[] = (
    comparisonTypes.map((comparisonType) => (
      {
        label: intl.formatMessage({
          id: `app.comparative-analysis.type-select.options.${comparisonType.id}`,
          defaultMessage: comparisonType.id
        }),
        leftIcon: comparativeAnalysisIcon,
        callback: () => {
          setComparativeType(comparisonType.id)
        }
      }
    ))
  )

  // Define the contents of the sub-menu of "Analyze" main task
  const analyzeMenuItemsData = [
    {
      label: intl.formatMessage({
        id: 'app.analyze-task.sub-task.Contract analysis',
        defaultMessage: 'Contract(s)'
      }),
      leftIcon: contractAnalysisIcon,
      // Sub-menus for Contract analysis, e.g., "Sanity check", "Comparative analysis", ...
      items: Object.values(AnalysisTaskAction).map((action) => (
        {
          label: intl.formatMessage({
            id: `app.analyze-task.contract-analysis.action.${action}`,
            defaultMessage: action
          }),
          leftIcon: analyzeTaskActionToIcon[action],
          callback: () => {
            updateAnalysisTaskParams({
              action
            })
          },
          // Sub-menus for Comparative analysis, e.g., "Term sheet vs. Contract", ...
          items: (
            action === AnalysisTaskAction.ComparativeAnalysis
              ? comparativeAnalysisItems
              : undefined
          ),
          // Side-by-side comparison is disabled, as it is not supported yet,
          // and Specific analysis is also disabled, because it does not work well enough
          disabled: action === AnalysisTaskAction.SideBySideComparison || (
            action === AnalysisTaskAction.SpecificAnalysis &&
            getConfig('COPILEX_SPECIAL_ANALYSIS_ENABLED') !== 'true'
          )
        }
      ))
    },
    {
      label: intl.formatMessage({
        id: 'app.analyze-task.sub-task.Brief analysis',
        defaultMessage: 'Brief analysis'
      }),
      leftIcon: briefAnalysisIcon,
      disabled: true
    },
    {
      label: intl.formatMessage({
        id: 'app.analyze-task.sub-task.Data room',
        defaultMessage: 'Data Room'
      }),
      leftIcon: dataRoomIcon,
      disabled: true
    },
    {
      label: intl.formatMessage({
        id: 'app.analyze-task.sub-task.Version compare',
        defaultMessage: 'Version Compare'
      }),
      leftIcon: versionCompareIcon,
      disabled: true
    }
  ]

  /**
   * Draft has a sub-menu to select the type of document to draft, e.g., "Contract", "Brief", ...,
   * Then, another sub-menu lets the user select between "Based on template" and "Based on custom instructions",
   * but only the former is supported yet.
   */
  const draftMenuItemsData: MenuItemData[] = ['Contract', 'Brief', 'Correspondence'].map((docType: DocType) => ({
    label: intl.formatMessage({
      id: `app.doc-type.${docType}`,
      defaultMessage: docType
    }),
    // Only contract is supported yet
    disabled: docType !== 'Contract',
    leftIcon: docTypeToDraftIcon[docType],
    items: [
      {
        label: intl.formatMessage({
          id: 'app.draft-task.sub-task.based-on-template',
          defaultMessage: 'Based on template'
        }),
        leftIcon: draftBasedOnTemplateIcon,
        callback: () => {
          setUserInputForm(prevForm => ({
            ...prevForm,
            mainTask: 'Draft'
          }))
        }
      },
      {
        label: intl.formatMessage({
          id: 'app.draft-task.sub-task.based-on-custom-instructions',
          defaultMessage: 'Based on custom instructions'
        }),
        leftIcon: draftBasedOnCustomInstructionsIcon,
        disabled: true
      }
    ]
  }))

  /**
   * Menu items data for main tasks, sub-tasks and their actions.
   * For example, "Contract analysis" -> "Deviation analysis" -> "Term sheet vs. Contract"
   */
  const menuItemsData: MenuItemData = {
    label: selectedTaskLabel,
    leftIcon: selectedTaskEntry.icon,
    items: [
      'Ask',
      'Search',
      'Analyze',
      'Draft',
      'Summarize',
      'Translate',
      'Redact',
      'Templatize'
    ].map(getMenuItemDataForMainTaskLabel)
  }

  return {
    selectedTaskEntry,
    menuItemsData,
    getMenuItemDataForMainTaskLabel
  }
}

export default useSelectTaskEntry
