import { type AttachmentsAndUploads } from 'features/documents'
import { type InputValidationResult } from 'features/send-message'
import { type SummarizeTaskParams, type UserInputForm } from 'features/user-input-form'

/**
 * Check if the input is valid for sending a message with a Summarize task.
 */
export const isValidSummarizeInput = (
  input: string,
  userInputForm: Partial<UserInputForm>,
  attachments: AttachmentsAndUploads
): InputValidationResult => {
  const taskParams = userInputForm.taskParams as SummarizeTaskParams | undefined
  if (
    taskParams === undefined ||
    taskParams.type === undefined ||
    taskParams.style === undefined ||
    taskParams.length === undefined
  ) {
    return [false, 'Select summarize options']
  }

  if (taskParams.length === null) {
    console.debug('> isValidSummarizeInput taskParams.length is null')
    return [false, 'Choose a summary length']
  }

  // For Summarize, we need either an input, an attachment, or both
  if (input.trim() !== '' || attachments.uploaded.length > 0) {
    return [true, '']
  } else {
    return [false, 'Add a document or enter instructions']
  }
}

export default isValidSummarizeInput
