import { IconButton, Tooltip } from '@mui/material'
import { useState, useMemo } from 'react'
import { useIntl } from 'react-intl'
import Icon from 'components/icons/feather'

interface ExportAnnotationButtonProps {
  onClick: () => void
}

export const ExportAnnotationButton: React.FC<ExportAnnotationButtonProps> = ({
  onClick
}: ExportAnnotationButtonProps) => {
  const intl = useIntl()
  const [clicked, setClicked] = useState<boolean>(false)

  const handleClick = (): void => {
    setClicked(true)
    onClick()
    // Reset clicked state after 5 seconds (for the animation to end)
    setTimeout(() => { setClicked(false) }, 1000)
  }

  const title = useMemo(() => intl.formatMessage({
    id: 'app.review-modal.export-annotation',
    defaultMessage: 'Export annotation (for development purposes)'
  }), [intl])

  return <Tooltip title={title}>
    <IconButton onClick={handleClick}>
      <Icon.Tag />
    </IconButton>
  </Tooltip>
}
