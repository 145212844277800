import { Typography } from '@mui/material'
import Loading from 'components/progress/Loading'
import { useSession } from 'features/sessions'
import { useSessions } from 'features/sessions/api/getSessions'
import { type Session } from '../types'
import { StyledList } from './ListSessions.styles'
import SessionItem from './SessionItem'
import { useIntl } from 'react-intl'
import { type FC, useMemo, useCallback } from 'react'

// Helper function to group sessions by date range
const getDateGroup = (date: Date): string => {
  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(yesterday.getDate() - 1)

  const isToday = date.toDateString() === today.toDateString()
  const isYesterday = date.toDateString() === yesterday.toDateString()

  if (isToday) return 'Today'
  if (isYesterday) return 'Yesterday'

  const daysDiff = Math.floor((today.getTime() - date.getTime()) / (1000 * 60 * 60 * 24))
  if (daysDiff <= 7) return 'Previous 7 days'
  if (daysDiff <= 30) return 'Previous 30 days'
  return 'More than 30 days ago'
}

// Helper to group sessions
const groupSessions = (sessions: Session[]): Map<string, Session[]> => {
  const grouped = new Map<string, Session[]>()

  sessions.forEach((session) => {
    const date = session.createdAt !== undefined ? new Date(session.createdAt) : new Date()
    const group = getDateGroup(date)

    if (!grouped.has(group)) {
      grouped.set(group, [])
    }
    grouped.get(group)?.push(session)
  })

  return grouped
}

export const ListSessions: FC = (): JSX.Element => {
  const intl = useIntl()
  const { isPending, isError, data = [], error } = useSessions()
  const { selectedSessionId, changeSelectedSession } = useSession()

  const translateDateGroup = useCallback((dateGroup: string): string => {
    return intl.formatMessage(
      { id: `app.sessions.date-group.${dateGroup}` },
      { defaultValue: dateGroup }
    )
  }, [intl])

  const handleSessionClick = useCallback((sessionId: string): void => {
    changeSelectedSession(sessionId)
  }, [changeSelectedSession])

  const groupedSessions = useMemo(() => groupSessions(data), [data])

  if (isPending) return <Loading />
  if (isError) return <p>Error: {error.message}</p>

  return (
    <StyledList className="sessions-list">
      <div className="sessions-list-content">
        {Array.from(groupedSessions.entries()).map(([dateGroup, sessions]) => (
          <div className="sessions-list-group" key={dateGroup}>
            <Typography variant="overline" color="primary.main">
              {translateDateGroup(dateGroup)}
            </Typography>
            {sessions.map((session: Session) => (
              <SessionItem
                key={session.id}
                session={session}
                selected={session.id === selectedSessionId}
                onClick={() => { handleSessionClick(session.id) }}
              />
            ))}
          </div>
        ))}
      </div>
    </StyledList>
  )
}

export default ListSessions
