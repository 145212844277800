import { useAuth0 } from '@auth0/auth0-react'
import { useAuth } from 'context/AuthContext'
import { getSessionMessages } from 'features/messages/api/getMessages'
import { updateMessage } from 'features/messages/api/updateMessage'
import { type Message } from 'features/messages/types'
import { useEffect, useState } from 'react'

interface UseMessagesProps {
  /**
   * The ID of the session to manage the messages for.
   * If undefined, this means it is a new session,
   * and we don't have a session ID yet.
   */
  sessionId: string | undefined
}

interface UseMessagesReturn {
  messages: Message[]
  setMessages: (messages: Message[]) => void
  onCorrectionSubmitted: (sessionId: string, idx: number, correction: string) => void
  onFeedbackSubmitted: (sessionId: string, idx: number, feedback: number) => void
}

/**
 * Hook to manage the messages of a given session.
 */
export const useMessages = ({ sessionId }: UseMessagesProps): UseMessagesReturn => {
  const currentUser = useAuth()
  const { getAccessTokenSilently } = useAuth0()
  const [messages, setMessages] = useState<Message[]>([])

  // If the session ID or current user changes,
  // fetch the messages for the new session.
  useEffect(() => {
    if (sessionId === null || sessionId === undefined) {
      setMessages([])
      return
    }

    void getAccessTokenSilently().then(async (token) =>
      await getSessionMessages(token, currentUser, sessionId)
    ).then(({ history }) => {
      setMessages(history)
    }).catch((error) => {
      console.error(error)
    })
  }, [sessionId, currentUser])

  const onCorrectionSubmitted = (sessionId: string, idx: number, correction: string): void => {
    if (sessionId === undefined || sessionId === null) throw new Error('Session ID not set!')

    void (async function () {
      const token = await getAccessTokenSilently()
      await updateMessage(token, currentUser, sessionId, idx, { correction })
    })()

    // Update the local state as well
    const newMessages = [...messages]
    newMessages[idx].correction = correction
    setMessages(newMessages)
  }

  /**
   * Handle the submission of a feedback (e.g., thumbs up/down) for a given message.
   */
  const onFeedbackSubmitted = (sessionId: string, idx: number, feedback: number): void => {
    if (sessionId === undefined || sessionId === null) throw new Error('Session ID not set!')

    // Update the message on the server
    void (async function () {
      const token = await getAccessTokenSilently()
      await updateMessage(token, currentUser, sessionId, idx, { feedback })
    })()

    // Optimistically update the local state
    const newMessages = [...messages]
    newMessages[idx].feedback = feedback
    setMessages(newMessages)
  }

  return {
    messages,
    setMessages,
    onCorrectionSubmitted,
    onFeedbackSubmitted
  }
}
