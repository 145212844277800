import { type FC } from 'react'
import { Box, FormControl, MenuItem, Select, type SelectChangeEvent } from '@mui/material'
import { useGlobals } from 'context/GlobalsContext'
import { useIntl } from 'react-intl'

interface Props {
  value: string
  onChange: (value: string) => void
}

export const SummarizeTypeSelect: FC<Props> = ({ value, onChange }: Props): JSX.Element => {
  const intl = useIntl()
  const globals = useGlobals()
  const types = globals.taskParams.summarize.types

  const handleChange = (event: SelectChangeEvent<string>): void => {
    onChange(event.target.value)
  }

  const getTranslatedType = (type: string): string => {
    return intl.formatMessage({
      id: `app.summarize.type-select.options.${type}`,
      defaultMessage: type
    })
  }

  return (
    <Box className="summarize-type-select" sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <FormControl fullWidth size="small">
        <Select
          labelId="summarize-type-select-label"
          value={value}
          onChange={handleChange}
          label={intl.formatMessage({
            id: 'app.summarize.type-select.label',
            defaultMessage: 'Summary Type'
          })}
        >
          {types.map((type) => (
            <MenuItem key={type} value={type}>
              {getTranslatedType(type)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default SummarizeTypeSelect
