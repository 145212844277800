import LaunchIcon from '@mui/icons-material/Launch'
import { Tooltip } from '@mui/material'
import { type FC, type ReactNode } from 'react'
import { HostnameTypography, LabelContainer, LinkTextTypography, SnippetTypography, StyledLink, StyledLinkChip, TitleTypography, TooltipContentContainer, tooltipSx } from './LinkChip.styles'

/**
 * Extract the hostname from an URL, e.g. https://eur-lex.europa.eu/eli/reg/2016/679/oj/eng -> eur-lex.europa.eu
 * @param href The URL of the link.
 * @returns The hostname.
 */
const extractHostname = (href: string): string => {
  const url = new URL(href)
  return url.hostname
}

interface Props {
  /**
   * The URL of the link.
   */
  href: string
  /**
   * The title of the page that the link points to.
   */
  title?: string
  /**
   * The snippet of the page that the link points to.
   */
  snippet?: string
  children: ReactNode
}

/**
 * Component that renders an external link as a chip with a launch icon.
 */
export const LinkChip: FC<Props> = ({ href, title, snippet, children }: Props): JSX.Element => {
  const hostname = extractHostname(href)
  const hasTooltipContent = title !== undefined || snippet !== undefined

  // Create a custom tooltip content with rich formatting
  const tooltipContent = hasTooltipContent
    ? (
      <TooltipContentContainer>
        <HostnameTypography>{hostname}</HostnameTypography>
        {title !== undefined && <TitleTypography>{title}</TitleTypography>}
        {snippet !== undefined && <SnippetTypography>{snippet}</SnippetTypography>}
      </TooltipContentContainer>
      )
    : undefined

  const chipLabel = (
    <LabelContainer>
      {/*
        Quickfix: Position the icon absolutely so it doesn't affect layout flow.
        Without this, the entire LinkChip would be misaligned (vertically)
        with the neighboring text.
      */}
      <LaunchIcon
        sx={{
          fontSize: 12,
          position: 'absolute',
          left: '-2px',
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 1
        }}
      />
      {/* Add left padding to make space for the icon */}
      <LinkTextTypography
        variant="body1"
        sx={{
          paddingLeft: '14px'
        }}
      >
        {children}
      </LinkTextTypography>
    </LabelContainer>
  )

  const chip = (
    <StyledLink
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <StyledLinkChip
        size="small"
        label={chipLabel}
        clickable
        // Ensure chip aligns with surrounding text
        sx={{
          verticalAlign: 'baseline',
          // Reduce height to match text
          height: 'auto',
          // Remove default margins
          margin: 0
        }}
      />
    </StyledLink>
  )

  return hasTooltipContent
    ? (
      <Tooltip
        title={tooltipContent}
        arrow
        placement="top"
        componentsProps={{
          tooltip: {
            sx: tooltipSx
          }
        }}
      >
        {chip}
      </Tooltip>
      )
    : chip
}

export default LinkChip
