import { isValidComparativeAnalysisInputs } from 'features/comparative-analysis'
import { type AttachmentsAndUploads } from 'features/documents'
import { type InputValidationResult } from 'features/send-message'
import { AnalysisTaskAction, type AnalysisTaskParams, type UserInputForm } from 'features/user-input-form'

/**
 * Check if the input is valid for sending a message with an analysis task.
 */
export const isValidAnalysisInput = (
  userInputForm: Partial<UserInputForm>,
  attachments: AttachmentsAndUploads
): InputValidationResult => {
  if (attachments.uploaded.length === 0) {
    return [false, 'Add a document to analyze']
  }

  const taskParams = userInputForm.taskParams as AnalysisTaskParams | undefined
  if (taskParams?.action === undefined) {
    return [false, 'Select an analysis type']
  }

  switch (taskParams.action) {
    case AnalysisTaskAction.SanityCheck:
      if (taskParams.sanityCheckOptions === undefined || taskParams.sanityCheckOptions.length === 0) {
        return [false, 'Select analysis options']
      }
      break
    case AnalysisTaskAction.ComparativeAnalysis:
      return isValidComparativeAnalysisInputs(taskParams, attachments)
    case AnalysisTaskAction.SpecificAnalysis:
      if (taskParams.specificAnalysisOptions === undefined || taskParams.specificAnalysisOptions.length === 0) {
        return [false, 'Select analysis options']
      }
      break
  }
  return [true, '']
}
