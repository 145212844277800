import { useAuth0 } from '@auth0/auth0-react'
import LogoutIcon from '@mui/icons-material/Logout'
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'

export const LogoutMenuItem: React.FC = () => {
  const intl = useIntl()
  const { logout } = useAuth0()

  const handleLogout = (): void => {
    const logoutURL = window.location.origin + '/login'
    void logout({ logoutParams: { returnTo: logoutURL } })
  }

  const logoutLabel = useMemo(() => intl.formatMessage({
    id: 'app.logout-button.tooltip',
    defaultMessage: 'Logout'
  }), [intl])

  return (
    <MenuItem key={'logout'} onClick={handleLogout}>
      <ListItemIcon>
        <LogoutIcon />
      </ListItemIcon>
      <ListItemText>
        {logoutLabel}
      </ListItemText>
    </MenuItem>
  )
}

export default LogoutMenuItem
