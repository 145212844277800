import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Typography
} from '@mui/material'
import type React from 'react'
import { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { formControlSx } from './TermsForm.styles'

interface Props {
  userFirstName?: string
  onAccept: () => void
}

export const TermsForm: React.FC<Props> = ({ userFirstName, onAccept }) => {
  const intl = useIntl()
  const [accepted, setAccepted] = useState<boolean>(false)
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState<boolean>(false)
  const termsContainerRef = useRef<HTMLDivElement>(null)

  const readBeforeAcceptingMsg = useMemo(() => intl.formatMessage({
    id: 'app.terms-form.read-before-accepting'
  }), [intl])

  const handleScroll = useCallback(() => {
    if (termsContainerRef.current != null) {
      const { scrollTop, scrollHeight, clientHeight } = termsContainerRef.current
      // Consider "scrolled to bottom" when within 10px of the bottom or at the bottom
      const isAtBottom = Math.abs(scrollHeight - clientHeight - scrollTop) < 10
      setHasScrolledToBottom(isAtBottom)
    }
  }, [])

  useEffect(() => {
    const container = termsContainerRef.current
    if (container != null) {
      container.addEventListener('scroll', handleScroll, { passive: true })
      // Check initial scroll position
      handleScroll()

      return () => {
        container.removeEventListener('scroll', handleScroll)
      }
    }
  }, [handleScroll])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    if (!accepted || !hasScrolledToBottom) throw new Error('Terms not accepted or not fully read!')

    event.preventDefault()
    onAccept()
  }

  const checkboxLabel = useMemo(() => intl.formatMessage({
    id: 'app.terms-form.checkbox.label'
  }), [intl])

  const confirmButtonLabel = useMemo(() => intl.formatMessage({
    id: 'app.terms-form.submit-button.label'
  }), [intl])

  const defaultUserFirstName = useMemo(() => intl.formatMessage({
    id: 'app.terms-form.default-user-first-name'
  }), [intl])

  const preamble = useMemo(() => intl.formatMessage({
    id: 'app.terms-form.preamble'
  }, { br: <br />, user_first_name: userFirstName ?? defaultUserFirstName }), [intl, userFirstName, defaultUserFirstName])

  const termHeader = useMemo(() => intl.formatMessage({
    id: 'app.terms-form.term-header'
  }, { br: <br /> }), [intl])

  const term1 = useMemo(() => intl.formatMessage({
    id: 'app.terms-form.term1'
  }, { br: <br /> }), [intl])

  const term2 = useMemo(() => intl.formatMessage({
    id: 'app.terms-form.term2'
  }, { br: <br /> }), [intl])

  const term3 = useMemo(() => intl.formatMessage({
    id: 'app.terms-form.term3'
  }, { br: <br /> }), [intl])

  const term4 = useMemo(() => intl.formatMessage({
    id: 'app.terms-form.term4'
  }, { br: <br />, link: (chunks: any) => <a target="_blank" rel="noreferrer" href={chunks}>{chunks}</a> }), [intl])

  const editForm = (
    <form onSubmit={handleSubmit}>
      <Box id={'terms-form-form-container'} sx={formControlSx}>
        <FormControl>
          <FormControlLabel
            control={<Checkbox
              id="terms-forms-checkbox"
              value={accepted}
              onChange={e => { setAccepted(e.target.checked) }}
              disabled={!hasScrolledToBottom}
            />}
            label={hasScrolledToBottom ? checkboxLabel : readBeforeAcceptingMsg}
          />
        </FormControl>

        <Button
          type="submit"
          variant="contained"
          disabled={!accepted || !hasScrolledToBottom}
        >
          {confirmButtonLabel}
        </Button>
      </Box>
    </form>
  )

  return (
    <Box id={'terms-form-container'} sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      minHeight: 0, // Allow container to shrink
      gap: 1
    }}>
      <Box id="terms-form-body" component={'div'} sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        minHeight: 0, // Allow body to shrink
        overflow: 'hidden', // Prevent body from growing beyond container
        gap: 1
      }}>
        <Typography variant="body1" sx={{ flexShrink: 0 }}>
          {preamble}
        </Typography>
        <Box
          id="terms-form-terms-container"
          ref={termsContainerRef}
          sx={{
            p: 1,
            flexGrow: 1,
            flexShrink: 1,
            overflowY: 'auto',
            minHeight: 0, // Allow terms to shrink
            maxHeight: '100%', // Prevent growing beyond container
            border: '1px solid gray'
          }}
        >
          <Typography variant="body1" fontSize={'12px'}>
            {termHeader}<br />
            <ul>
              <li>{term1}</li>
              <li>{term2}</li>
              <li>{term3}</li>
              <li>{term4}</li>
            </ul>
          </Typography>
        </Box>
      </Box>
      <Box sx={{ flexShrink: 0 }}> {/* Prevent form from shrinking */}
        {editForm}
      </Box>
    </Box>
  )
}

export default TermsForm
