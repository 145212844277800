import React, { useContext } from 'react'
import { type UserProfile } from 'features/users/types'
import { type User as Auth0User } from '@auth0/auth0-spa-js'

/**
 * Combines the Auth0 auth data with the user's profile data from the API.
 */
export class User {
  userProfile: UserProfile
  auth0User: Auth0User

  constructor (userProfile: UserProfile, auth0User: Auth0User) {
    this.userProfile = userProfile
    this.auth0User = auth0User
    if (auth0User.sub === undefined) {
      throw new Error('Auth0 user has no sub field')
    }
  }

  uid (): string {
    return this.auth0User.sub as string
  }

  email (): string | null {
    return this.userProfile.email
  }
}

export const AuthContext = React.createContext<User | null | undefined>(
  undefined
)

export function useAuth (): User {
  return useContext<User | null | undefined>(AuthContext) as User
}
