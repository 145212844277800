import {
  Autocomplete,
  Box,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import CloseModalButton from 'components/buttons/CloseModalButton'
import HelpTooltipButton from 'components/buttons/HelpTooltipButton'
import { useGlobals } from 'context/GlobalsContext'
import { type AreaOfLaw, type Industry, type Jurisdiction, type UserRole } from 'features/globals'
import { type UpdateUserProfileParams } from 'features/users/api/updateUserProfile'
import { useUpdateUserProfile } from 'features/users/api/useUpdateUserProfile'
import { useUserProfile } from 'features/users/api/useUserProfile'
import { useEffect, useMemo, useState, type FC } from 'react'
import { useIntl } from 'react-intl'
import { ModalHeader, ModalTitle } from 'theme'
import CustomLabel from './CustomLabel'
import { StyledDialog } from './UserProfileModal.styles'
import Loading from 'components/progress/Loading'

interface Props {
  open: boolean
  onClose: () => void
  /**
   * Whether the modal is being used in the onboarding flow.
   * In that case, we want to force the user to fill in all mandatory fields
   * before they can continue, with no possibility to cancel or close the modal.
   */
  onboardingVersion?: boolean
}

/**
 * A modal for updating the user's profile.
 */
export const UserProfileModal: FC<Props> = ({
  open,
  onClose,
  onboardingVersion = false
}): JSX.Element => {
  const globals = useGlobals()
  const intl = useIntl()

  const { data: userProfile, isLoading: isUserProfileLoading } = useUserProfile()
  const { mutate: updateUserProfileMutation, isPending: isUpdateUserProfilePending } = useUpdateUserProfile()

  const [firstName, setFirstName] = useState<string>(userProfile?.firstName ?? '')
  const [lastName, setLastName] = useState<string>(userProfile?.lastName ?? '')
  const [orgName, setOrgName] = useState<string>(userProfile?.orgName ?? '')
  const [role, setRole] = useState<string>(userProfile?.role ?? '')
  const [title, setTitle] = useState<string>(userProfile?.title ?? '')
  const [legalFieldsOfExpertise, setLegalFieldsOfExpertise] = useState<AreaOfLaw[]>([])
  const [industriesOfExpertise, setIndustriesOfExpertise] = useState<Industry[]>([])
  const [mainJurisdictions, setMainJurisdictions] = useState<Jurisdiction[]>([])
  const [userPreferences, setUserPreferences] = useState<string>(userProfile?.userPreferences ?? '')
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false)

  // Check if the form is valid (all required fields are filled)
  const isFormValid = useMemo((): boolean => {
    return (
      firstName.trim() !== '' &&
      lastName.trim() !== '' &&
      orgName.trim() !== '' &&
      role.trim() !== '' &&
      title.trim() !== ''
    )
  }, [firstName, lastName, orgName, role, title])

  // Field validation helpers
  const isFieldInvalid = (value: string): boolean => {
    return formSubmitted && value.trim() === ''
  }

  // Update state when userProfile changes
  useEffect(() => {
    console.debug('> UserProfileModal [userProfile]')

    if (userProfile != null) {
      setFirstName(userProfile.firstName ?? '')
      setLastName(userProfile.lastName ?? '')
      setOrgName(userProfile.orgName ?? '')
      setRole(userProfile.role ?? '')
      setTitle(userProfile.title ?? '')

      // These fields might not be in the UserProfile type yet, so we're being cautious
      if ('legalFieldsOfExpertise' in userProfile && Array.isArray(userProfile.legalFieldsOfExpertise)) {
        setLegalFieldsOfExpertise(userProfile.legalFieldsOfExpertise)
      }

      if ('industriesOfExpertise' in userProfile && Array.isArray(userProfile.industriesOfExpertise)) {
        setIndustriesOfExpertise(userProfile.industriesOfExpertise)
      }

      if ('mainJurisdictions' in userProfile && Array.isArray(userProfile.mainJurisdictions)) {
        setMainJurisdictions(userProfile.mainJurisdictions)
      }
    }
  }, [userProfile])

  const handleSubmit = (): void => {
    setFormSubmitted(true)

    // If the form is not valid, it means there are no null values,
    // but the type system does not know that and therefore we need to check for null values
    if (!isFormValid || firstName === null || lastName === null || orgName === null || role === null || title === null) {
      return
    }

    const params: UpdateUserProfileParams = {
      firstName,
      lastName,
      orgName,
      role,
      title,
      legalFieldsOfExpertise: legalFieldsOfExpertise.length > 0 ? legalFieldsOfExpertise : null,
      industriesOfExpertise: industriesOfExpertise.length > 0 ? industriesOfExpertise : null,
      mainJurisdictions: mainJurisdictions.length > 0 ? mainJurisdictions : null,
      userPreferences: userPreferences.length > 0 ? userPreferences : null
    }

    updateUserProfileMutation(
      { params },
      {
        onSuccess: () => {
          onClose()
        }
      }
    )
  }

  if (isUserProfileLoading) {
    return <Loading />
  }

  return (
    <StyledDialog
      className="user-profile-modal"
      open={open}
      onClose={(event, reason) => {
        // Prevent closing when backdrop is clicked
        if (reason !== 'backdropClick') {
          onClose()
        }
      }}
      maxWidth="md"
      fullWidth
      disableEscapeKeyDown
    >
      <DialogTitle>
        <ModalHeader className="user-profile-modal-header">
          <ModalTitle className="user-profile-modal-title">
            {intl.formatMessage({
              id: 'app.user-menu.user-profile-modal.modal-title',
              defaultMessage: 'Personalize Your Profile'
            })}
          </ModalTitle>
          {!onboardingVersion ? <CloseModalButton onClick={onClose} /> : null}
        </ModalHeader>
      </DialogTitle>

      <DialogContent>
        {
          // During onboarding, we want to explain the purpose of the profile customization.
          onboardingVersion
            ? <Typography variant="body2" gutterBottom sx={{ mt: 2 }}>
              {intl.formatMessage({
                id: 'app.user-menu.user-profile-modal.onboarding-explanation',
                defaultMessage: 'Please provide details about you, your current position and your preferences, Copilex will take them into account to output personalized answers, tailored to your needs and instructions.'
              })}
            </Typography>
            : null
        }
        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Grid className="personal-info-section-container" item xs={12}>
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="subtitle1">
                {intl.formatMessage({
                  id: 'app.user-menu.user-profile-modal.personal-info',
                  defaultMessage: 'Personal Information'
                })}
              </Typography>
              <HelpTooltipButton tooltip={intl.formatMessage({
                id: 'app.user-menu.user-profile-modal.privacy-notice-tooltip'
              })} />
            </Box>
          </Grid>

          <Grid className="first-name-container" item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label={intl.formatMessage({
                id: 'app.user-menu.user-profile-modal.first-name',
                defaultMessage: 'First Name'
              })}
              value={firstName}
              onChange={(e) => { setFirstName(e.target.value) }}
              error={isFieldInvalid(firstName)}
              helperText={isFieldInvalid(firstName)
                ? intl.formatMessage({
                  id: 'app.user-menu.user-profile-modal.required-field-error',
                  defaultMessage: 'This field is required'
                })
                : ''}
            />
          </Grid>

          <Grid className="last-name-container" item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label={intl.formatMessage({
                id: 'app.user-menu.user-profile-modal.last-name',
                defaultMessage: 'Last Name'
              })}
              value={lastName}
              onChange={(e) => { setLastName(e.target.value) }}
              error={isFieldInvalid(lastName)}
              helperText={isFieldInvalid(lastName)
                ? intl.formatMessage({
                  id: 'app.user-menu.user-profile-modal.required-field-error',
                  defaultMessage: 'This field is required'
                })
                : ''}
            />
          </Grid>

          <Grid className="organization-container" item xs={12}>
            <TextField
              required
              fullWidth
              label={intl.formatMessage({
                id: 'app.user-menu.user-profile-modal.organization',
                defaultMessage: 'Organization'
              })}
              value={orgName ?? ''}
              onChange={(e) => {
                setOrgName(e.target.value)
              }}
              error={isFieldInvalid(orgName)}
              helperText={isFieldInvalid(orgName)
                ? intl.formatMessage({
                  id: 'app.user-menu.user-profile-modal.required-field-error',
                  defaultMessage: 'This field is required'
                })
                : ''}
            />
          </Grid>

          <Grid className="role-container" item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={formSubmitted && role === null}
            >
              <CustomLabel label={intl.formatMessage({
                id: 'app.user-menu.user-profile-modal.select-role.label',
                defaultMessage: 'Role'
              })} required />
              <Select
                id="role-select"
                required
                value={role ?? ''}
                onChange={(e): void => {
                  setRole(e.target.value)
                }}
                // Use medium size to match the text fields
                size="medium"
              >
                <MenuItem
                  value=""
                  disabled={role !== null}
                >
                  <em>{intl.formatMessage({
                    id: 'app.user-menu.user-profile-modal.select-role.placeholder',
                    defaultMessage: 'Select a role'
                  })}</em>
                </MenuItem>
                {globals.user.roles.map((roleOption: UserRole) => (
                  <MenuItem key={roleOption} value={roleOption}>
                    {
                      intl.formatMessage({
                        // ESLint does not get that roleOption is a string
                        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                        id: `app.user-menu.user-profile-modal.select-role.options.${roleOption}`,
                        defaultMessage: roleOption
                      })
                    }
                  </MenuItem>
                ))}
              </Select>
              {formSubmitted && role === null && (
                <FormHelperText error>
                  {intl.formatMessage({
                    id: 'app.user-menu.user-profile-modal.required-field-error',
                    defaultMessage: 'This field is required'
                  })}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid className="title-container" item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label={intl.formatMessage({
                id: 'app.user-menu.user-profile-modal.title.label',
                defaultMessage: 'Title'
              })}
              placeholder={intl.formatMessage({
                id: 'app.user-menu.user-profile-modal.title.placeholder',
                defaultMessage: 'Partner, Counsel, Associate...'
              })}
              value={title ?? ''}
              onChange={(e) => {
                setTitle(e.target.value)
              }}
              error={isFieldInvalid(title)}
              helperText={isFieldInvalid(title)
                ? intl.formatMessage({
                  id: 'app.user-menu.user-profile-modal.required-field-error',
                  defaultMessage: 'This field is required'
                })
                : ''}
            />
          </Grid>

          <Grid className="expertise-section-container" item xs={12}>
            <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
              {intl.formatMessage({
                id: 'app.user-menu.user-profile-modal.expertise',
                defaultMessage: 'Expertise'
              })}
            </Typography>
          </Grid>

          <Grid className="legal-field-container" item xs={12}>
            <CustomLabel label={intl.formatMessage({
              id: 'app.user-menu.user-profile-modal.select-legal-fields.label',
              defaultMessage: 'Legal Fields of Expertise'
            })} />
            <Autocomplete
              multiple
              options={globals.user.areasOfLaw}
              value={legalFieldsOfExpertise}
              onChange={(_, newValue) => { setLegalFieldsOfExpertise(newValue as AreaOfLaw[]) }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={intl.formatMessage({
                    id: 'app.user-menu.user-profile-modal.select-legal-fields.placeholder',
                    defaultMessage: 'Add a field'
                  })}
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const tagProps = getTagProps({ index })
                  // React keys must be passed directly to JSX without using spread
                  const { key, ...otherProps } = tagProps
                  return (
                    <Chip
                      key={key}
                      variant="outlined"
                      label={
                        intl.formatMessage({
                          id: `app.user-menu.user-profile-modal.select-legal-fields.options.${option}`,
                          defaultMessage: option
                        })
                      }
                      {...otherProps}
                    />
                  )
                })
              }
              renderOption={({ key, ...otherProps }, option) => (
                <li key={key} {...otherProps}>
                  {intl.formatMessage({
                    id: `app.user-menu.user-profile-modal.select-legal-fields.options.${option}`,
                    defaultMessage: option
                  })}
                </li>
              )}
            />
          </Grid>

          <Grid className="industry-container" item xs={12}>
            <CustomLabel label={intl.formatMessage({
              id: 'app.user-menu.user-profile-modal.select-industries.label',
              defaultMessage: 'Industries of Expertise'
            })} />
            <Autocomplete
              multiple
              options={globals.user.industries}
              value={industriesOfExpertise}
              onChange={(_, newValue) => { setIndustriesOfExpertise(newValue as Industry[]) }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={intl.formatMessage({
                    id: 'app.user-menu.user-profile-modal.select-industries.placeholder',
                    defaultMessage: 'Add an industry'
                  })}
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const tagProps = getTagProps({ index })
                  // React keys must be passed directly to JSX without using spread
                  const { key, ...otherProps } = tagProps
                  return (
                    <Chip
                      key={key}
                      variant="outlined"
                      label={
                        intl.formatMessage({
                          id: `app.user-menu.user-profile-modal.select-industries.options.${option}`,
                          defaultMessage: option
                        })
                      }
                      {...otherProps}
                    />
                  )
                })
              }
              renderOption={({ key, ...otherProps }, option) => (
                <li key={key} {...otherProps}>
                  {intl.formatMessage({
                    id: `app.user-menu.user-profile-modal.select-industries.options.${option}`,
                    defaultMessage: option
                  })}
                </li>
              )}
            />
          </Grid>

          <Grid className="jurisdiction-container" item xs={12}>
            <CustomLabel label={intl.formatMessage({
              id: 'app.user-menu.user-profile-modal.select-jurisdictions.label',
              defaultMessage: 'Main jurisdictions'
            })} />
            <Autocomplete
              multiple
              options={globals.countryNames}
              value={mainJurisdictions}
              onChange={(_, newValue) => { setMainJurisdictions(newValue as Jurisdiction[]) }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={intl.formatMessage({
                    id: 'app.user-menu.user-profile-modal.select-jurisdictions.placeholder',
                    defaultMessage: 'Add a jurisdiction'
                  })}
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const tagProps = getTagProps({ index })
                  // React keys must be passed directly to JSX without using spread
                  const { key, ...otherProps } = tagProps
                  return (
                    <Chip
                      key={key}
                      variant="outlined"
                      label={
                        intl.formatMessage({
                          id: `app.user-menu.user-profile-modal.select-jurisdictions.options.${option}`,
                          defaultMessage: option
                        })
                      }
                      {...otherProps}
                    />
                  )
                })
              }
              renderOption={({ key, ...otherProps }, option) => (
                <li key={key} {...otherProps}>
                  {intl.formatMessage({
                    id: `app.user-menu.user-profile-modal.select-jurisdictions.options.${option}`,
                    defaultMessage: option
                  })}
                </li>
              )}
            />
          </Grid>
        </Grid>

        <Grid className="preferences-section-container" item xs={12}>
          <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
            {intl.formatMessage({
              id: 'app.user-menu.user-profile-modal.preferences.label',
              defaultMessage: 'Preferences'
            })}
          </Typography>
        </Grid>

        <Grid className="preferences-container" item xs={12}>
          <TextField
            fullWidth
            placeholder={intl.formatMessage({
              id: 'app.user-menu.user-profile-modal.preferences.placeholder',
              defaultMessage: 'Add a preference'
            })}
            value={userPreferences}
            onChange={(e) => { setUserPreferences(e.target.value) }}
            multiline
            rows={3}
          />
        </Grid>
      </DialogContent>

      <DialogActions>
        {!onboardingVersion && (
          <Button onClick={onClose}>
            {intl.formatMessage({
              id: 'app.user-menu.user-profile-modal.cancel-button.label',
              defaultMessage: 'Cancel'
            })}
          </Button>
        )}
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={isUpdateUserProfilePending || !isFormValid}
        >
          {intl.formatMessage({
            id: onboardingVersion
              ? 'app.user-menu.user-profile-modal.continue-button.label'
              : 'app.user-menu.user-profile-modal.submit-button.label',
            defaultMessage: onboardingVersion ? 'Continue' : 'Save'
          })}
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

export default UserProfileModal
