import { Select, styled } from '@mui/material'

export const StyledSelect = styled(Select<string>)({
  width: '150px',
  '& .MuiSelect-select': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
})
