import { Person } from '@mui/icons-material'
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { useUserProfile } from 'features/users/api/useUserProfile'
import { type FC } from 'react'
import { useIntl } from 'react-intl'

interface Props {
  onClick: () => void
}

/**
 * A menu item for the user profile. See {@link UserProfileModal} for the associated modal.
 */
export const ProfileMenuItem: FC<Props> = ({ onClick }: Props): JSX.Element => {
  const intl = useIntl()
  const { isLoading: isLoadingProfile } = useUserProfile()

  return (
    <MenuItem onClick={onClick} disabled={isLoadingProfile}>
      <ListItemIcon>
        <Person />
      </ListItemIcon>
      <ListItemText>
        {intl.formatMessage({
          id: 'app.user-menu.user-profile-menu-item.label',
          defaultMessage: 'Profile & Preferences'
        })}
      </ListItemText>
    </MenuItem>
  )
}

export default ProfileMenuItem
