import { Box, Checkbox, ListItemIcon, ListItemText, MenuItem, OutlinedInput, Select, TextField, type FilterOptionsState, type SelectChangeEvent, type SxProps, type Theme } from '@mui/material'
import Autocomplete, { createFilterOptions, type AutocompleteChangeReason, type AutocompleteRenderOptionState } from '@mui/material/Autocomplete'
import { useEffect, useRef, useState, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { optionsSelectSx } from './OptionsSelect.styles'
import { SelectInputLabel } from './select/SelectInputLabel'

export interface Option {
  id: number
  name: string
  icon?: React.ReactNode
}

export const createOptions = (values: Array<string | { name: string, icon?: React.ReactNode }>): Option[] => {
  return values.map((value, index) => ({
    id: index + 1,
    name: typeof value === 'string' ? value : value.name,
    icon: typeof value === 'string' ? undefined : value.icon
  }))
}

interface Props {
  items: Option[]
  label?: string
  onChange: (selectedOptions: Option[]) => void
  limitTags?: number
  sx?: SxProps<Theme>
  /**
   * The component to use.
   * - 'AutoComplete': Allows to type the options, and show selected options as badges.
   * - 'Select': A classic select menu with checkboxes.
   */
  component?: 'AutoComplete' | 'Select'
  /**
   * Whether the menu should appear above or below the button.
   */
  placement?: 'top' | 'bottom'
  /**
   * What to render in the dropdown menu when it is closed.
   * - name: show the name of the selected options
   * - icon: show the icon of the selected options.
   *         This is useful when the option has a distinctive icon and we can save space by not showing the name.
   * - label: show the label of the select, i.e., does not change no matter what options are selected
   */
  render?: 'name' | 'icon' | 'label'
  /**
   * Whether to select all options by default.
   */
  selectAllByDefault?: boolean
}

/**
 * A component that allows the user to select multiple options from a list.
 */
const OptionsSelect: React.FC<Props> = ({
  items,
  label,
  onChange,
  limitTags,
  component = 'AutoComplete',
  placement = 'bottom',
  render = 'name',
  selectAllByDefault = false,
  sx
}: Props) => {
  const intl = useIntl()
  const windowWidth = useRef<number>(0)

  const [selectedOptions, setSelectedOptions] = useState<Option[]>(selectAllByDefault ? items : [])
  const [defaultLimitTags, setDefaultLimitTags] = useState<number>(1)

  useEffect(() => {
    const onResize = (): void => {
      windowWidth.current = window.innerWidth
      // Default limit on tags displayed will depend on the screen width,
      // to avoid overflowing the screen.
      setDefaultLimitTags(
        windowWidth.current < 1600
          ? 1
          : windowWidth.current < 1900
            ? 2
            : 3
      )
    }

    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  // If any item has an id of 0, which is reserved for the 'select all' option,
  // throw an error.
  if (items.some(item => item.id === 0)) {
    throw new Error('Item with id 0 is reserved for the "select all" option')
  }

  const allSelected = items.length === selectedOptions.length

  const selectAllLabel = useMemo(() => intl.formatMessage({
    id: 'app.select-all',
    defaultMessage: 'Select all'
  }), [intl])

  const handleToggleOption = (selectedOps: Option[]): void => { setSelectedOptions(selectedOps) }
  const handleClearOptions = (): void => { setSelectedOptions([]) }

  const handleSelectAll = (isSelected: boolean): void => {
    if (isSelected) {
      setSelectedOptions(items)
    } else {
      handleClearOptions()
    }
  }

  const handleToggleSelectAll = (): void => {
    handleSelectAll?.(!allSelected)
  }

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    selectedOps: Option[],
    reason: AutocompleteChangeReason
  ): void => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (selectedOps.find((option) => option.name === selectAllLabel) != null) {
        handleToggleSelectAll()
        const result = items.filter((el) => el.name !== selectAllLabel)
        onChange(result)
      } else {
        handleToggleOption?.(selectedOps)
        onChange(selectedOps)
      }
    } else if (reason === 'clear') {
      handleClearOptions?.()
    }
  }

  const filter = createFilterOptions<Option>()

  const filterOptions = (
    options: Option[],
    params: FilterOptionsState<Option>
  ): Option[] => {
    const filtered = filter(options, params)
    return [{ id: 0, name: selectAllLabel }, ...filtered]
  }

  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: Option,
    { selected }: AutocompleteRenderOptionState
  ): React.ReactNode => {
    const checkboxProps =
      option.name === selectAllLabel ? { checked: allSelected } : {}
    // Work-around to avoid a warning (see https://github.com/mui/material-ui/issues/39833)
    // eslint-disable-next-line react/prop-types
    const { key, ...rest } = props as (React.HTMLAttributes<HTMLLIElement> & { key?: React.Key })
    return (
      <li key={key} {...rest}>
        <Checkbox checked={selected} {...checkboxProps} />
        {option.name}
      </li>
    )
  }

  const handleSelect = (event: SelectChangeEvent<string[]>): void => {
    const newSelectedOptions = items.filter(item => event.target.value.includes(item.name))
    setSelectedOptions(newSelectedOptions)
    onChange(newSelectedOptions)
  }

  // Customize the label to appear only when the user has not selected any option.
  // In other words, the label will be much like a placeholder text.
  const inputLabel = (
    <SelectInputLabel
      showLabel={selectedOptions.length === 0}
    >
      {label}
    </SelectInputLabel>
  )

  if (component === 'AutoComplete') {
    return (
      <>
        {inputLabel}
        <Autocomplete
          size='small'
          multiple
          disableCloseOnSelect
          options={items}
          value={selectedOptions}
          getOptionLabel={(option: Option) => option.name}
          filterOptions={filterOptions}
          renderOption={renderOption}
          renderInput={(params) => (
            <TextField {...params} />
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={handleChange}
          // Limit the number of tags that can be displayed,
          // and show the rest as a "+n" string.
          limitTags={limitTags ?? defaultLimitTags}
          sx={{ ...optionsSelectSx, ...sx }}
          slotProps={{
            popper: {
              // Make it appear above the button, not below
              placement
            }
          }}
        />
      </>
    )
  }

  const input = (
    <OutlinedInput
      label={label}
      // Highlight the border when the user has selected any option.
      sx={{
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: selectedOptions.length > 0 ? 'primary.dark' : undefined
        }
      }} />
  )

  return (
    <>
      {inputLabel}
      <Select
        size='small'
        multiple
        input={input}
        value={selectedOptions.map(option => option.name)}
        renderValue={(selected) => {
          if (render === 'icon') {
            return (
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 0.5 }}>
                {selectedOptions.map(option => option.icon)}
              </Box>
            )
          } else if (render === 'label') {
            return label
          }
          return selected.join(', ')
        }}
        onChange={handleSelect}
        MenuProps={{
          anchorOrigin: {
            vertical: placement,
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: placement === 'top' ? 'bottom' : 'top',
            horizontal: 'left'
          }
        }}
        sx={{ ...sx }}
      >
        {items.map((item) => (
          <MenuItem key={item.id} value={item.name}>
            <Checkbox
              checked={selectedOptions.some(option => option.id === item.id)}
            />
            {item.icon !== undefined && (
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
            )}
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
      </Select>
    </>
  )
}

export default OptionsSelect
