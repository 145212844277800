import { type InputValidationResult } from 'features/send-message'
import { type UserInputForm } from 'features/user-input-form'
import { type DraftTaskParams } from 'features/draft/types'

/**
 * Check if the input is valid for sending a message with a draft task.
 */
export const isValidDraftInput = (
  input: string,
  userInputForm: Partial<UserInputForm>
): InputValidationResult => {
  const taskParams = userInputForm.taskParams as DraftTaskParams | undefined
  if (taskParams === undefined) {
    return [false, 'Select draft options']
  }

  // Must have defined template and sources
  if (taskParams.templateId === null) {
    return [false, 'Select a template']
  }

  if (
    (
      taskParams.sourceIds === undefined ||
      taskParams.sourceIds === null ||
      taskParams.sourceIds.length === 0
    ) &&
    input.trim() === ''
  ) {
    return [false, 'Upload another document or enter some information']
  }

  return [true, '']
}

export default isValidDraftInput
