import type React from 'react'
import { useIntl } from 'react-intl'
import Markdown from './Markdown'
import { StyledBox, StyledUserInputTypography } from './MessageText.styles'
import { type Source } from 'features/messages'

interface Props {
  content: string
  sources?: Source[] | null
  showRaw?: boolean
}

const MessageText: React.FC<Props> = ({ content, sources = [], showRaw = false }: Props) => {
  const intl = useIntl()

  return (
    <StyledBox className='message-text'>
      {
        content.length > 0
          ? (
              showRaw
                ? <StyledUserInputTypography className='raw-message-text' showRaw={showRaw}>{content}</StyledUserInputTypography>
                : <Markdown sources={sources}>{content}</Markdown>
            )
          : <Markdown>
              {
                intl.formatMessage({
                  id: 'app.message-text.no-additional-input',
                  defaultMessage: '*No additional input*'
                })
              }
            </Markdown>
      }
    </StyledBox>
  )
}

export default MessageText
