import { styled, Dialog } from '@mui/material'

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2, 3)
  },
  '& .MuiDialogTitle-root': {
    padding: theme.spacing(2, 2)
  }
}))
