import { Tooltip } from '@mui/material'
import { type Source } from 'features/messages'
import MuiMarkdown, { type Overrides } from 'mui-markdown'
import { useMemo, type FC, type ReactNode } from 'react'
import LinkChip from './LinkChip'

// Define the props type for the anchor component
interface AnchorProps {
  children: ReactNode
  'data-title'?: string
  'data-snippet'?: string
  href?: string
  [key: string]: unknown
}

// Create an overrides object to pass to MuiMarkdown,
// to change its default styles which are not to our liking.
const getOverrides = (sources: Source[]): Overrides => ({
  // When formatting the "raw" message, we want to wrap the text.
  pre: {
    props: {
      style: {
        textWrap: 'wrap'
      }
    }
  },
  // Links can correspond to a source, in which case we want to display a tooltip
  // with the source title and snippet.
  a: {
    component: ({ children, ...props }: AnchorProps): JSX.Element => {
      const source = sources.find((s) => s.url === props.href)
      const title = source?.title
      const snippet = source?.snippet
      const href = props.href ?? ''
      // Display a tooltip to preview the source page title and snippet, if available.
      // const tooltip = <div>{title ?? ''}{' | '}{props.href}<br />{snippet ?? ''}</div>
      return (
        <LinkChip
          href={href}
          title={title}
          snippet={snippet}
        >
          {children}
        </LinkChip>
      )
    }
  }
})

interface Props {
  children: ReactNode
  sources?: Source[] | null
}

/**
 * Component that renders a markdown message.
 */
const Markdown: FC<Props> = ({ children, sources = [] }: Props): JSX.Element => {
  const overrides = useMemo(() => getOverrides(sources ?? []), [sources])
  return <MuiMarkdown overrides={overrides}>{String(children)}</MuiMarkdown>
}

export default Markdown
