import { Box, styled, Typography } from '@mui/material'
import { textMdSemiBold } from './typography'

export const ModalContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.background.paper,
  maxWidth: '1200px',
  padding: theme.spacing(1.5),
  gap: theme.spacing(1),
  width: 'auto',
  height: 'auto',
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: theme.spacing(1),
  [theme.breakpoints.only('xs')]: {
    width: '100%',
    height: '100%',
    gap: 0,
    border: '0px',
    borderRadius: 0
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}))

export const ModalHeader = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'stretch'
}))

export const ModalTitle = styled(Typography)(({ theme }) => ({
  ...textMdSemiBold,
  fontSize: '24px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1)
}))
