import { TextField } from '@mui/material'
import { type FC } from 'react'

interface Props {
  value: number | null
  onChange: (value: number | null) => void
}

export const SummarizeLengthInput: FC<Props> = ({ value, onChange }: Props): JSX.Element => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const inputValue = event.target.value

    if (inputValue === '') {
      // Allow empty input (null value)
      onChange(null)
    } else {
      const newValue = parseInt(inputValue, 10)
      if (!isNaN(newValue) && newValue > 0) {
        onChange(newValue)
      }
    }
  }

  return (
    <TextField
      className="summarize-length-input"
      type="number"
      value={value === null ? '' : value}
      onChange={handleChange}
      inputProps={{
        min: 1,
        step: 1
      }}
      fullWidth
      size="small"
      sx={{
        maxWidth: '100px'
      }}
    />
  )
}

export default SummarizeLengthInput
