import { type FC } from 'react'
import { SummarizeTypeSelect } from './SummarizeTypeSelect'
import useSummarizeTaskParams from 'features/summarize/hooks/useSummarizeTaskParams'
import { useGlobals } from 'context/GlobalsContext'
import Loading from 'components/progress/Loading'

export const SummarizeTypeSelectContainer: FC = (): JSX.Element => {
  const globals = useGlobals()
  const { summarizeTaskParams, updateSummarizeTaskParams } = useSummarizeTaskParams()

  if (summarizeTaskParams === null) {
    return <Loading />
  }

  const defaultType = globals.taskParams.summarize.types[0]

  const handleChange = (value: string): void => {
    updateSummarizeTaskParams({ type: value })
  }

  // Use the value from summarizeTaskParams if available, otherwise use the default
  const type = summarizeTaskParams.type ?? defaultType

  return (
    <SummarizeTypeSelect
      value={type}
      onChange={handleChange}
    />
  )
}

export default SummarizeTypeSelectContainer
