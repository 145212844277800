import {
  Typography,
  useTheme
} from '@mui/material'
import { type FC } from 'react'

interface Props {
  label: string
  required?: boolean
}

/**
 * A label to place above a select or text field, designed to look like the InputLabel
 * for components that don't have it.
 */
export const CustomLabel: FC<Props> = ({ label, required = false }) => {
  const theme = useTheme()

  return <Typography
    variant="subtitle1"
    // Hack to make it look like the InputLabel above the text fields
    sx={{
      fontSize: '14px',
      lineHeight: '1.4375em',
      fontWeight: 500,
      color: theme.palette.mode === 'light' ? theme.palette.grey[900] : theme.palette.grey[100],
      mb: 1
    }}
  >
    {
      // If the labeled field is required, we add an asterisk to the label to indicate that.
      // For some reason it is not done by MUI despite adding `required` to the Select field.
      label + (required ? ' *' : '')
    }
  </Typography>
}

export default CustomLabel
