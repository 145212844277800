import { type RuntimeConfig } from 'types/global'

/**
 * Default values for optional configuration keys.
 */
const DEFAULT_CONFIG: Partial<Record<keyof RuntimeConfig, string>> = {
  COPILEX_SPECIAL_ANALYSIS_ENABLED: 'false',
  COPILEX_DRAFT_ENABLED: 'false'
}

/**
 * Helper function to get the configuration value for the given key,
 * which works both in development and production modes.
 *
 * @param key - The configuration key to retrieve
 * @param options - Additional options
 * @returns The configuration value for the key
 */
export const getConfig = (
  key: keyof RuntimeConfig
): string => {
  const defaultValue: string | null = key in DEFAULT_CONFIG ? DEFAULT_CONFIG[key] as string : null

  // Check if there is a value in the environment variable
  // that was set at build time
  const envValue = process.env[`REACT_APP_${key}`]
  if (envValue !== undefined) {
    return envValue
  }
  // Otherwise, use the value from config.js,
  // which is set at runtime
  const runtimeValue = window.RUNTIME_CONFIG[key]

  // If we have a valid runtime value, validate and return it
  if (runtimeValue !== undefined && runtimeValue !== null && runtimeValue !== '') {
    // Check for placeholder values
    if (runtimeValue.startsWith('%%') && runtimeValue.endsWith('%%')) {
      if (defaultValue !== null) {
        console.debug(`Using default value for ${key}: ${defaultValue}`)
        return defaultValue
      }
      throw new Error(`Configuration key ${key} still contains a placeholder!`)
    } else {
      return runtimeValue
    }
  }

  if (defaultValue !== null) {
    console.debug(`Using default value for ${key}: ${defaultValue}`)
    return defaultValue
  }

  // If we got here, there's no valid value and no default
  if (runtimeValue === undefined || runtimeValue === null) {
    throw new Error(`Configuration key ${key} is not set!`)
  }
  if (runtimeValue === '') {
    throw new Error(`Configuration key ${key} is empty!`)
  }

  // Return empty string if we're not throwing
  return ''
}

export default getConfig
