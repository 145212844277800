import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import Chat from 'components/central-pane/chat/Chat'
import { Compose } from 'components/central-pane/compose/Compose'
import TitleBar from 'components/central-pane/title-bar/TitleBar'
import CopilexLogo from 'components/left-pane/CopilexLogo'
import { LeftPane } from 'components/left-pane/LeftPane'
import { PdfSearchProviders } from 'components/PdfViewer'
import ReviewModal from 'components/review-modal/ReviewModal/ReviewModal'
import { CentralPane, SidepanesProvider } from 'components/sidepanes'
import DocumentPanel from 'features/documents/components/DocumentPanel'
import DocumentPanelProvider from 'features/documents/providers/DocumentPanelProvider'
import { useChatMessages } from 'features/messages/hooks/useChatMessages'
import { useSession } from 'features/sessions'
import type React from 'react'
import { Suspense } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'

const HomePage = (): JSX.Element => {
  const { sessionId } = useParams<string>()
  const theme = useTheme()
  const intl = useIntl()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const {
    messages,
    input,
    attachmentIds,
    existingEntities,
    processingInput,
    reviewModalOpen,
    reviewModalInitResponse,
    onSubmitInput,
    onReviewSubmitted,
    onReviewSkipped,
    onReviewCancelled,
    onCorrectionSubmitted,
    onFeedbackSubmitted
  } = useChatMessages({ sessionId })

  const { selectedSession: session } = useSession()

  const withProviders = (children: React.ReactNode): JSX.Element => (
    <SidepanesProvider>
      <PdfSearchProviders>
        <DocumentPanelProvider>
          {children}
        </DocumentPanelProvider>
      </PdfSearchProviders>
    </SidepanesProvider>
  )

  const mobileView = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        padding: theme.spacing(3),
        textAlign: 'center',
        gap: theme.spacing(2)
      }}
    >
      <CopilexLogo />
      <Typography variant="body1">
        {
          intl.formatMessage({
            id: 'app.home-page.mobile-view.message',
            defaultMessage: 'Sorry, Copilex does not support mobile devices yet. Please login from your laptop or desktop.'
          })
        }
      </Typography>
    </Box>
  )

  const Disclaimer = (): JSX.Element => (
    <Box className="disclaimer-container" sx={{
      justifyContent: 'center',
      textAlign: 'center'
    }}>
      <Typography className="disclaimer-text" variant="body1" sx={{
        fontSize: '0.8rem',
        color: theme.palette.text.secondary
      }}>
        {
          intl.formatMessage({
            id: 'app.home-page.disclaimer',
            defaultMessage: 'Copilex can still make mistakes, check important info.'
          })}
      </Typography>
    </Box>
  )

  const desktopView = (
    <Box className="App" sx={{
      display: 'flex',
      flexDirection: 'row',
      height: '100vh',
      minWidth: 0,
      overflow: 'hidden'
    }}>
      {/* Left pane that contains the user settings and the sessions list */}
      <LeftPane />

      {/* Central pane displaying the chat and compose */}
      <CentralPane>
        <TitleBar />
        {session !== null && (
          <Chat
            session={session}
            messages={messages}
            onCorrectionSubmitted={(idx, correction) => { onCorrectionSubmitted(session.id, idx, correction) }}
            onFeedbackSubmitted={(idx, feedback) => { onFeedbackSubmitted(session.id, idx, feedback) }} />
        )}
        {sessionId != null && (
          <Suspense>
            <Compose
              initInput={input}
              processing={processingInput}
              onSubmit={onSubmitInput}
            />
            <Disclaimer />
          </Suspense>
        )}
      </CentralPane>

      {/* Right pane: Document panel */}
      <DocumentPanel />

      {reviewModalInitResponse !== undefined && <ReviewModal
        open={reviewModalOpen}
        input={input}
        attachmentIds={attachmentIds}
        initExistingEntities={existingEntities}
        initResponse={reviewModalInitResponse}
        onClose={onReviewCancelled}
        onSkip={onReviewSkipped}
        onSubmit={onReviewSubmitted}
      />}
    </Box>
  )

  return withProviders(
    isMobile ? mobileView : desktopView
  )
}

export default HomePage
