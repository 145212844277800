import { Typography } from '@mui/material'
import useDocumentPanel from 'features/documents/hooks/useDocumentPanel'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import BackToListView from './BackToListView'
import StyledBox from './DocumentPanelHeader.styles'
import SecondFileToggleButton from './SecondFileToggleButton'
/**
 * Adds at the top of the document panel some buttons to navigate
 * between the different views.
 */
export const DocumentPanelHeader = (): JSX.Element => {
  const intl = useIntl()
  const { state } = useDocumentPanel()

  const label = useMemo(() => intl.formatMessage({ id: 'app.document-panel.header.label', defaultMessage: 'Document panel' }), [intl])

  return (
    <StyledBox className='document-panel-header'>
      {state.viewMode !== 'LIST_VIEW' && <BackToListView />}
      {state.viewMode === 'LIST_VIEW' && <Typography variant='overline'>{label}</Typography>}
      {state.viewMode !== 'LIST_VIEW' && <SecondFileToggleButton />}
    </StyledBox>
  )
}

export default DocumentPanelHeader
