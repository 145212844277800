import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import ErrorModalContainer from 'components/error-modal/ErrorModalContainer'
import { ErrorModalProvider } from 'context/ErrorModalContext'
import { IntlProviderWrapper } from 'context/IntlProviderWrapper'
import { type FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { ProgressBackdropContainer } from './components/progress/ProgressBackdropContainer'
import ColorModeContext from './context/ColorModeContext'
import { ProgressProvider } from './context/ProgressContext'
import './index.css'
import Root from './Root'
import { getCookies, setCookie } from './services/cookies'
import { queryClient } from './services/react-query'
import { getTheme } from './theme'

// Memoize static components
const MemoizedRoot = memo(Root)
const MemoizedErrorModalContainer = memo(ErrorModalContainer)
const MemoizedProgressBackdropContainer = memo(ProgressBackdropContainer)
const MemoizedReactQueryDevtools = memo(ReactQueryDevtools)

const App: FC = (): JSX.Element => {
  const cookies = useMemo(() => getCookies(), [])
  const storedColorMode = useMemo(() =>
    cookies.get('colorMode') as ('light' | 'dark' | undefined),
  [cookies]
  )

  const [colorMode, setColorMode] = useState<'light' | 'dark'>(storedColorMode ?? 'light')

  const theme = useMemo(
    () => getTheme(colorMode),
    [colorMode]
  )

  const handleColorModeChange = useCallback((newMode: 'light' | 'dark'): void => {
    setColorMode(newMode)
  }, [])

  const colorContextValue = useMemo(() => ({
    colorMode,
    setColorMode: handleColorModeChange
  }), [colorMode, handleColorModeChange])

  // Save color mode to cookies
  useEffect(() => {
    setCookie('colorMode', colorMode)
  }, [colorMode])

  return (
    <IntlProviderWrapper>
      <ColorModeContext.Provider value={colorContextValue}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ErrorModalProvider>
            <ProgressProvider>
              <QueryClientProvider client={queryClient}>
                <MemoizedRoot />
                <MemoizedReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
              <MemoizedProgressBackdropContainer />
            </ProgressProvider>
            <MemoizedErrorModalContainer />
          </ErrorModalProvider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </IntlProviderWrapper>
  )
}

export default memo(App)
