import UploadInProgress from 'features/documents/components/UploadInProgress'
import { type AttachmentsAndUploads } from 'features/documents/types'
import type React from 'react'
import AttachmentChipContainer from './AttachmentChipContainer'
import StyledStack from './AttachmentsStack.styles'
import { isUploadInProgress } from 'features/documents/utils/uploadInprogress'

interface Props {
  attachments: AttachmentsAndUploads
  deletable?: boolean
  openable?: boolean
}

/**
 * A stack (row) of attached files, represented as chips.
 */
export const AttachmentsStack: React.FC<Props> = (
  { attachments, deletable = true, openable = true }: Props
) => {
  return <StyledStack direction='row' spacing={1}>
    {attachments.uploaded.map((a) => (
      <AttachmentChipContainer
        key={a.id}
        attachment={a}
        deletable={deletable}
        openable={openable}
      />
    ))}
    {isUploadInProgress(attachments) && <UploadInProgress />}
  </StyledStack>
}

export default AttachmentsStack
