import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import { type FC } from 'react'
import { useIntl } from 'react-intl'
import DisabledOverlay from './DisabledOverlay'
import { ComponentWrapper, TipContainer, TipDescription } from './TipContainer.style'

export const TipFollowUp: FC = (): JSX.Element => {
  const intl = useIntl()
  return (
    <TipContainer>
      <ComponentWrapper>
        <DisabledOverlay>
          <QuestionAnswerIcon />
        </DisabledOverlay>
      </ComponentWrapper>
      <TipDescription>
        {
          intl.formatMessage({
            id: 'app.chat.splash-screen.tips.tip-follow-up'
          })
        }
      </TipDescription>
    </TipContainer>
  )
}

export default TipFollowUp
