import { type Evidence } from 'features/analyze/types'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { StyledTypography } from './EvidenceLocation.styles'

interface Props {
  evidence: Evidence
}

export const EvidenceLocation = ({ evidence }: Props): JSX.Element => {
  const intl = useIntl()

  const locationNotAvailable = useMemo(() => intl.formatMessage({
    id: 'app.doc-review.result-type.evidence.location-not-available',
    defaultMessage: 'N/A'
  }), [intl])

  const startingWith = (
    (evidence.location !== null && evidence.startingWith !== null && evidence.startingWith !== '')
      ? intl.formatMessage({
        id: 'app.doc-review.result-type.evidence.starting-with',
        defaultMessage: ', starting with "{startingWith}..."'
      }, { startingWith: evidence.startingWith })
      : ''
  )

  return (
    <StyledTypography>
      {evidence.location ?? locationNotAvailable}
      {startingWith}
    </StyledTypography>
  )
}
