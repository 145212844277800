import { FilterList, SwapVert } from '@mui/icons-material'
import { FormControl, MenuItem, Select } from '@mui/material'
import OptionsSelect, { createOptions, type Option } from 'components/OptionsSelect'
import { ISSUE_NON_ISSUE_TYPE_VALUES, type IssueNonIssueType, type SortCriteria } from 'features/analyze/hooks/useDocReviewIssuesFilter'
import { COMPARATIVE_ANALYSIS_NATURE_VALUES, CRITICALITY_VALUES, type ComparativeAnalysisNature, type Criticality, type IssueCategory, type IssueType } from 'features/analyze/types'
import { SanityCheckOption } from 'features/user-input-form'
import { useIntl } from 'react-intl'
import { capitalizeFirstLetter } from 'services/utils'
import ComparativeAnalysisNatureDisplay from './ComparativeAnalysisNatureDisplay'
import CriticalityDisplay from './CriticalityDisplay'
import { formControlSx, StyledStack } from './DocReviewIssuesFilters.styles'
import { useMemo } from 'react'

interface Props {
  /**
   * The issue category to apply the filters to, e.g. internal or contextual.
   * This is used to determine the options and filters to display.
   */
  issueCategory: IssueCategory
  sortBy: string
  type: IssueNonIssueType
  onTypeChange: (type: IssueNonIssueType) => void
  onSortChange: (criteria: SortCriteria) => void
  onCriticalitiesChange: (value: Criticality[]) => void
  onNaturesChange?: (value: ComparativeAnalysisNature[]) => void
  onIssueTypesChange?: (value: IssueType[]) => void
}

/**
 * Display sorting and filtering options for the issues.
 * This component is used for both internal and contextual issues,
 * and will display the appropriate filters depending on the issue category.
 */
const DocReviewIssuesFilters: React.FC<Props> = ({
  issueCategory,
  sortBy,
  type,
  onTypeChange,
  onSortChange,
  onCriticalitiesChange,
  onNaturesChange,
  onIssueTypesChange
}: Props): JSX.Element => {
  const intl = useIntl()

  const criticalityOptions: Option[] = createOptions(
    CRITICALITY_VALUES.map((criticality) => ({
      name: intl.formatMessage({ id: `app.doc-review.result-type.criticality.${criticality}`, defaultMessage: capitalizeFirstLetter(criticality) }),
      icon: <CriticalityDisplay criticality={criticality} />
    }))
  )

  const natureOptions: Option[] = createOptions(
    COMPARATIVE_ANALYSIS_NATURE_VALUES.map((nature) => ({
      name: intl.formatMessage({ id: `app.doc-review.result-type.nature.${nature}`, defaultMessage: capitalizeFirstLetter(nature) }),
      icon: <ComparativeAnalysisNatureDisplay nature={nature} />
    }))
  )

  // Options for the issue types will depend on the issue category.
  const issueTypeOptions: Option[] = createOptions(
    // For internal issues, it corresponds to the sanity check options.
    issueCategory === 'Internal'
      ? Object.values(SanityCheckOption).map((option) => ({
        name: intl.formatMessage({ id: `app.doc-review.sanity-check.options.${option}` })
      }))
      // For contextual or compliance issues, there is nothing to choose from for now, and it should not be displayed.
      : []
  )

  const criticalityLabel = useMemo(() => intl.formatMessage({ id: 'app.doc-review.result-type.criticality-select.label', defaultMessage: 'Criticality' }), [intl])
  const typeLabel = useMemo(() => intl.formatMessage({ id: 'app.doc-review.filters.type-label', defaultMessage: 'Type' }), [intl])
  const natureLabel = useMemo(() => intl.formatMessage({ id: 'app.doc-review.result-type.nature-select.label', defaultMessage: 'Nature' }), [intl])
  const checksLabel = useMemo(() => intl.formatMessage({ id: 'app.doc-review.sanity-check.options-select.label', defaultMessage: 'Checks' }), [intl])

  return (
    <StyledStack>
      <FormControl size="small" sx={formControlSx}>
        <Select
          startAdornment={<SwapVert />}
          labelId="sort-by-label"
          value={sortBy}
          label={intl.formatMessage({ id: 'app.doc-review.filters.sort-by-label', defaultMessage: 'Sort by' })}
          onChange={(e) => {
            onSortChange(e.target.value as SortCriteria)
          }}
          aria-label={intl.formatMessage({ id: 'app.doc-review.filters.sort-by-label', defaultMessage: 'Sort by' })}
        >
          <MenuItem value="document-order">
            {intl.formatMessage({ id: 'app.doc-review.filters.sort-by.document-order', defaultMessage: 'Document order' })}
          </MenuItem>
          <MenuItem value="criticality-high-to-low">
            {intl.formatMessage({ id: 'app.doc-review.filters.sort-by.criticality-high-to-low', defaultMessage: 'Criticality (High to Low)' })}
          </MenuItem>
          <MenuItem value="criticality-low-to-high">
            {intl.formatMessage({ id: 'app.doc-review.filters.sort-by.criticality-low-to-high', defaultMessage: 'Criticality (Low to High)' })}
          </MenuItem>
        </Select>
      </FormControl>

      <FormControl size="small" sx={formControlSx}>
        <Select
          startAdornment={<FilterList />}
          labelId="type-label"
          value={type}
          label={typeLabel}
          aria-label={typeLabel}
          onChange={(e) => {
            onTypeChange(e.target.value as IssueNonIssueType)
          }}
        >
          {ISSUE_NON_ISSUE_TYPE_VALUES.map((type) => (
            <MenuItem key={type} value={type}>
              {intl.formatMessage({ id: `app.doc-review.filters.type.${type}`, defaultMessage: capitalizeFirstLetter(type) })}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl size="small" sx={formControlSx}>
        <OptionsSelect
          component={'Select'}
          render={'label'}
          selectAllByDefault={true}
          label={criticalityLabel}
          items={criticalityOptions}
          onChange={(selected) => {
            // In OptionsSelect, id 0 is reserved for the "Select all" option,
            // so we need to subtract 1 from the selected option's id to get the correct index
            onCriticalitiesChange(selected.map(option => CRITICALITY_VALUES[option.id - 1]))
          }}
        />
      </FormControl>

      {/* Nature is only relevant for contextual issues. */}
      {issueCategory === 'Contextual' && onNaturesChange !== undefined && (
        <FormControl size="small" sx={formControlSx}>
          <OptionsSelect
            component={'Select'}
            render={'label'}
            selectAllByDefault={true}
            label={natureLabel}
            items={natureOptions}
            onChange={(selected) => {
              // In OptionsSelect, id 0 is reserved for the "Select all" option,
              // so we need to subtract 1 from the selected option's id to get the correct index
              onNaturesChange(selected.map(option => COMPARATIVE_ANALYSIS_NATURE_VALUES[option.id - 1]))
            }}
          />
        </FormControl>
      )}

      {/* Issue types are currently only relevant for internal issues, to filter by the sanity check options. */}
      {issueCategory === 'Internal' && issueTypeOptions.length > 0 && onIssueTypesChange !== undefined && (
        <FormControl size="small" sx={formControlSx}>
          <OptionsSelect
            component={'Select'}
            render={'label'}
            selectAllByDefault={true}
            label={checksLabel}
            items={issueTypeOptions}
            onChange={(selected) => {
              // In OptionsSelect, id 0 is reserved for the "Select all" option,
              // so we need to subtract 1 from the selected option's id to get the correct index
              const options = Object.values(SanityCheckOption) as SanityCheckOption[]
              onIssueTypesChange(selected.map(option => options[option.id - 1]))
            }}
          />
        </FormControl>
      )}
    </StyledStack>
  )
}

export default DocReviewIssuesFilters
