import { type AxiosError } from 'axios'
import { type UserProfile } from 'features/users'
import { axiosInstance, createAuthHeaderFromToken, defaultTTL } from 'services/axios'

export const getUserProfile = async (token: string): Promise<UserProfile | null> => {
  try {
    const response = await axiosInstance.get<UserProfile>(
      '/users/me',
      {
        headers: createAuthHeaderFromToken(token),
        id: 'get-user-profile',
        cache: {
          ttl: defaultTTL
        }
      }
    )
    return {
      ...response.data,
      fullName: response.data.firstName !== null && response.data.lastName !== null
        ? `${response.data.firstName} ${response.data.lastName}`
        : null
    }
  } catch (err: unknown) {
    const error = err as AxiosError
    if (error.response === undefined) throw error
    if (error.response.status === 404) return null
    throw error
  }
}
