import { ContentPasteRounded, ContentPasteTwoTone } from '@mui/icons-material'
import { IconButton, Tooltip, type SxProps, type Theme } from '@mui/material'
import { useState } from 'react'
import { useIntl } from 'react-intl'

interface CopyToClipboardButtonProps {
  buttonSxProps?: SxProps<Theme>
  onClick: () => void
  tooltip?: string
  size?: 'small' | 'medium'
}

export const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({
  onClick,
  tooltip,
  size = 'small'
}: CopyToClipboardButtonProps) => {
  const intl = useIntl()
  const [clicked, setClicked] = useState<boolean>(false)

  const handleClick = (): void => {
    setClicked(true)
    onClick()
    // Reset clicked state after 5 seconds (for the animation to end)
    setTimeout(() => { setClicked(false) }, 1000)
  }

  const title = tooltip ?? intl.formatMessage({
    id: 'app.copy-to-clipboard',
    defaultMessage: 'Copy to clipboard'
  })

  return <Tooltip title={title}>
    <IconButton onClick={handleClick} size={size}>
      {clicked ? <ContentPasteTwoTone fill='inherit' /> : <ContentPasteRounded style={{ fill: 'gray' }} />}
    </IconButton>
  </Tooltip>
}
