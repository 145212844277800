import { Box, type SxProps, type Theme, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material'
import { ActiveRuleIcon, AutoRuleColoredIcon, AutoRuleIcon, ExcludeRuleIcon } from 'components/review-modal/switches/RuleIcons'
import { useColorMode } from 'context/ColorModeContext'
import { type FC, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { type ReviewStateItem, isDefaultSettingItem } from 'services/reviewState'
import { copilexBlueColor } from 'theme'

export const toggleButtonSx = (activeColor: string): SxProps<Theme> => ({
  // Make it look disabled by default, because it is not selected
  color: 'gray',
  '.MuiSvgIcon-root': {
    color: 'gray'
  },
  '&:hover': {
    color: activeColor,
    '.MuiSvgIcon-root': {
      color: activeColor
    }
  },
  '&.Mui-selected': {
    color: activeColor,
    '.MuiSvgIcon-root': {
      color: activeColor
    }
  }
})

export enum RuleSwitchState {
  EXCLUDE = 'exclude',
  AUTO = 'auto',
  ALWAYS = 'always'
}

interface Props {
  item: ReviewStateItem
  // active: boolean
  // isUserDefined: boolean
  onChange?: (value: RuleSwitchState) => void
}

const computeState = (item: ReviewStateItem): RuleSwitchState => {
  switch (item.anonymize) {
    case false:
      return RuleSwitchState.EXCLUDE
    case null:
      return RuleSwitchState.AUTO
    case true:
      return RuleSwitchState.ALWAYS
  }
}

/**
 * Switch controlling the application of global user specific rules
 */
const RuleTripleSwitch: FC<Props> = ({ item, onChange }: Props) => {
  const intl = useIntl()
  const { colorMode } = useColorMode()

  const [value, setValue] = useState<RuleSwitchState>(computeState(item))

  useEffect(() => {
    setValue(computeState(item))
  }, [item])

  const disabled = isDefaultSettingItem(item)

  const contrastColor = colorMode === 'dark' ? 'white' : 'black'

  const buttonGroup = (
    <ToggleButtonGroup
      value={value}
      exclusive
      disabled={disabled}
      size='small'
      onChange={(event, newValue) => {
        if (newValue !== null) {
          setValue(newValue)
          onChange?.(newValue)
        }
      }}
      aria-label='rule switch'
    >
      {/* "Exclude" button */}
      <ToggleButton
        value={RuleSwitchState.EXCLUDE}
        aria-label={RuleSwitchState.EXCLUDE}
        sx={toggleButtonSx(contrastColor)}
      >
        <ExcludeRuleIcon />
      </ToggleButton>

      {/* "Auto" button */}
      <ToggleButton
        value={RuleSwitchState.AUTO}
        aria-label={RuleSwitchState.AUTO}
        sx={toggleButtonSx(copilexBlueColor)}
      >
        {
          value === RuleSwitchState.AUTO
            ? <AutoRuleColoredIcon />
            : <AutoRuleIcon />
        }
      </ToggleButton>

      {/* "Always" button */}
      <ToggleButton
        value={RuleSwitchState.ALWAYS}
        aria-label={RuleSwitchState.ALWAYS}
        sx={toggleButtonSx(contrastColor)}
      >
        <ActiveRuleIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  )

  const tooltipTitle = useMemo(() => intl.formatMessage({
    id: 'app.rule-triple-switch.disabled-tooltip',
    defaultMessage: 'This is part of the default settings, can\'t change it'
  }), [intl])

  return (
    disabled
      ? (
          <Tooltip title={tooltipTitle}>
            {/* Position the icon to appear aligned with switch's icon */}
            <Box
              className='rule-triple-switch'
              sx={{ display: 'flex', justifyContent: 'right', paddingRight: '8px' }}
            >
              <ActiveRuleIcon fill={contrastColor} />
            </Box>
          </Tooltip>
        )
      : buttonGroup
  )
}

export default RuleTripleSwitch
