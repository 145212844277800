import { Typography } from '@mui/material'
import { NoWrap } from 'components/central-pane/compose/UserPromptButtons.styles'
import { type SummarizeTaskParams } from 'features/user-input-form'
import { type FC } from 'react'
import { useIntl } from 'react-intl'

interface Props {
  taskParams: SummarizeTaskParams
}

export const SummarizeFormDigest: FC<Props> = ({ taskParams }: Props): JSX.Element => {
  const intl = useIntl()

  const lengthInput = taskParams.length?.toString() ?? ''
  const type = intl.formatMessage({
    id: `app.summarize.type-select.options.${taskParams.type}`,
    defaultMessage: taskParams.type
  })
  const style = intl.formatMessage({
    id: `app.summarize.style-select.options.${taskParams.style}`,
    defaultMessage: taskParams.style
  })

  return (
    <Typography>
      {
        intl.formatMessage({
          id: 'app.user-input-form-digest.summarize'
        }, {
          lengthInput,
          type: <NoWrap>{type},</NoWrap>,
          style
        })
      }
    </Typography>
  )
}

export default SummarizeFormDigest
