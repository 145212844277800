import { type FC } from 'react'
import { SummarizeStyleSelect } from './SummarizeStyleSelect'
import useSummarizeTaskParams from 'features/summarize/hooks/useSummarizeTaskParams'
import { useGlobals } from 'context/GlobalsContext'
import Loading from 'components/progress/Loading'

export const SummarizeStyleSelectContainer: FC = (): JSX.Element => {
  const globals = useGlobals()
  const { summarizeTaskParams, updateSummarizeTaskParams } = useSummarizeTaskParams()

  if (summarizeTaskParams === null) {
    return <Loading />
  }

  const defaultStyle = globals.taskParams.summarize.styles[0]

  const handleChange = (value: string): void => {
    updateSummarizeTaskParams({ style: value })
  }

  // Use the value from summarizeTaskParams if available, otherwise use the default
  const style = summarizeTaskParams.style ?? defaultStyle

  return (
    <SummarizeStyleSelect
      value={style}
      onChange={handleChange}
    />
  )
}

export default SummarizeStyleSelectContainer
