import { UserInputFormProvider } from 'features/user-input-form/hooks/useUserInputForm'
import WelcomePage from 'pages/WelcomePage'
import type React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AuthenticationGuard from './components/AuthenticationGuard'
import { Auth0ProviderWithNavigate } from './context/Auth0ProviderWithNavigate'
import { GlobalsProvider } from './context/GlobalsContext'
import { SessionProvider } from './features/sessions'
import './index.css'
import AdminPage from './pages/AdminPage'
import AnonPlaygroundPage from './pages/AnonPlaygroundPage'
import CallbackPage from './pages/CallbackPage'
import DesignSystemPage from './pages/DesignSystemPage'
import HomePage from './pages/HomePage'
import LoginPage from './pages/LoginPage'
import LogoutPage from './pages/LogoutPage'
import NotFoundPage from './pages/NotFoundPage'
import SignUpPage from './pages/SignUpPage'

const Root: React.FC = (): JSX.Element => {
  /**
   * Wrap the HomePage with the providers it needs to have access to.
   * This must be done here because the HomePage uses hooks
   * that need the user to be authenticated.
   * AuthenticationGuard requires a proper component to be passed to it and not
   * a JSX expression, thus we created this constant.
   */
  const HomePageWithProviders: React.FC = (): JSX.Element => {
    return (
      <GlobalsProvider>
        <SessionProvider>
          <UserInputFormProvider>
            <HomePage />
          </UserInputFormProvider>
        </SessionProvider>
      </GlobalsProvider>
    )
  }

  return (
    // Remove a warning from the console by enabling new React Router v7 features
    <BrowserRouter future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
      <Auth0ProviderWithNavigate>
        <Routes>
          <Route path="/" element={<AuthenticationGuard component={HomePageWithProviders}/>}>
            <Route path=":sessionId" element={<AuthenticationGuard component={HomePageWithProviders}/>} />
          </Route>

          <Route path="/callback" element={<CallbackPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/signup" element={<SignUpPage />} />

          <Route path="/anon" element={
            <AuthenticationGuard component={AnonPlaygroundPage} />
          } />
          <Route path="/admin" element={
            <AuthenticationGuard component={AdminPage} />
          } />

          <Route path="/welcome" element={<WelcomePage />} />

          {/* Design System route - only in development */}
          {process.env.NODE_ENV === 'development' && (
            <Route
              path="/design"
              element={
                <DesignSystemPage />
              }
            />
          )}

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  )
}

export default Root
