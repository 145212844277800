import { useGlobals } from 'context/GlobalsContext'
import { useUserInputForm } from 'features/user-input-form/hooks/useUserInputForm'
import { type SummarizeTaskParams } from 'features/user-input-form/types'
import { useEffect, useRef } from 'react'

interface UseSummarizeTaskParamsReturn {
  /**
   * The task parameters for the Summarize task.
   * If the task is not a Summarize task, the value is `null`.
   */
  summarizeTaskParams: SummarizeTaskParams | null

  /**
   * Update the task parameters for the Summarize task.
   */
  updateSummarizeTaskParams: (update: Partial<SummarizeTaskParams>) => void
}

/**
 * Hook to get and update the options for the Summarize task,
 * which are nested within the user input form state.
 */
const useSummarizeTaskParams = (): UseSummarizeTaskParamsReturn => {
  const globals = useGlobals()
  const { userInputForm, setUserInputForm } = useUserInputForm()

  // Hook to initialize the taskParams with default value when task got changed to Summarize
  // Only run this effect when the task is first set to Summarize
  useEffect(() => {
    if (userInputForm.mainTask !== 'Summarize') {
      return
    }

    console.debug('> useSummarizeTaskParams [userInputForm.mainTask]', userInputForm.mainTask)

    // Check if taskParams already has values, if so, don't override them
    const taskParams = userInputForm.taskParams as SummarizeTaskParams | undefined
    if (taskParams?.length !== undefined &&
        taskParams?.type !== undefined &&
        taskParams?.style !== undefined) {
      return
    }

    // Otherwise, initialize the taskParams with default values
    updateSummarizeTaskParams({
      length: globals.taskParams.summarize.defaultLength,
      type: globals.taskParams.summarize.types[0],
      style: globals.taskParams.summarize.styles[0]
    })
  }, [userInputForm.mainTask])

  const summarizeTaskParams: SummarizeTaskParams | null = (
    userInputForm.mainTask !== 'Summarize' || userInputForm.taskParams === undefined)
    ? null
    : userInputForm.taskParams as SummarizeTaskParams

  const updateSummarizeTaskParams = (update: Partial<SummarizeTaskParams>): void => {
    console.debug('> useSummarizeTaskParams updateSummarizeTaskParams', update)

    setUserInputForm(prevForm => {
      if (prevForm.mainTask !== 'Summarize') {
        return prevForm
      }

      return {
        ...prevForm,
        taskParams: {
          ...(prevForm.taskParams as SummarizeTaskParams),
          ...update
        }
      }
    })
  }

  return { summarizeTaskParams, updateSummarizeTaskParams }
}

export default useSummarizeTaskParams
