import Loading from 'components/progress/Loading'
import React from 'react'
import CacheBuster from 'react-cache-buster'
import ReactDOM from 'react-dom/client'
import { pdfjs } from 'react-pdf'
import packageInfo from '../package.json'
import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'

// Import the CSS files to support annotation (e.g. links) and text layer
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'

// Set the worker source for PDF.js, needed to use react-pdf to view PDFs.
// This file must be copied to the public folder by the build process,
// see scripts/pdfjs/copy-worker.mjs
pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const isProduction = process.env.NODE_ENV === 'production'

root.render(
  <CacheBuster
    currentVersion={packageInfo.version}
    isEnabled={isProduction} // If false, the library is disabled.
    isVerboseMode={false} // If true, the library writes verbose logs to console.
    loadingComponent={<Loading verticallyCentered />} // If not pass, nothing appears at the time of new version check.
    metaFileDirectory={'.'} // If public assets are hosted somewhere other than root on your server.
  >
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </CacheBuster>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
