import { type FC } from 'react'
import { Box, FormControl, InputLabel, MenuItem, Select, type SelectChangeEvent } from '@mui/material'
import { useGlobals } from 'context/GlobalsContext'
import { useIntl } from 'react-intl'

interface Props {
  value: string
  onChange: (value: string) => void
}

export const SummarizeStyleSelect: FC<Props> = ({ value, onChange }: Props): JSX.Element => {
  const intl = useIntl()
  const globals = useGlobals()
  const styles = globals.taskParams.summarize.styles

  const handleChange = (event: SelectChangeEvent<string>): void => {
    onChange(event.target.value)
  }

  const getTranslatedStyle = (style: string): string => {
    return intl.formatMessage({
      id: `app.summarize.style-select.options.${style}`,
      defaultMessage: style
    })
  }

  return (
    <Box className="summarize-style-select" sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <FormControl fullWidth size="small">
        <Select
          labelId="summarize-style-select-label"
          value={value}
          onChange={handleChange}
          label={intl.formatMessage({
            id: 'app.summarize.style-select.label',
            defaultMessage: 'Summary Style'
          })}
        >
          {styles.map((style) => (
            <MenuItem key={style} value={style}>
              {getTranslatedStyle(style)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default SummarizeStyleSelect
