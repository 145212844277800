import RectangleIcon from '@mui/icons-material/Rectangle'
import { SvgIcon } from '@mui/material'
import * as CountryFlags from 'country-flag-icons/react/3x2'
import { FlagComponent } from 'country-flag-icons/react/3x2'
import { type FC } from 'react'
import { DEFAULT_ICON_SIZE_IN_PX } from './types'

interface Props {
  lang: string
  size?: number
}

const languageToCountryCode: Record<string, string> = {
  en: 'GB',
  es: 'ES',
  fr: 'FR',
  de: 'DE',
  it: 'IT',
  ja: 'JP',
  ko: 'KR',
  nl: 'NL',
  pt: 'PT',
  ru: 'RU',
  zh: 'CN',
  ar: 'SA'
}

// Map language codes to country codes used by the country-flag-icons package.
// Upper-casing the language code should match the component name in the country-flag-icons package.
const languageToFlagComponent = (lang: string): FlagComponent | null => {
  const countryCode = languageToCountryCode[lang]
  if (countryCode === undefined) {
    return null
  }

  // Check if that country code exists in CountryFlags keys
  return Object.prototype.hasOwnProperty.call(CountryFlags, countryCode)
    ? CountryFlags[countryCode as keyof typeof CountryFlags]
    : null
}

/**
 * Icon to display a language using it's flag.
 */
const LanguageIcon: FC<Props> = ({ lang, size = DEFAULT_ICON_SIZE_IN_PX }: Props): JSX.Element => {
  // Dynamically get the flag component
  const Flag = languageToFlagComponent(lang) ?? RectangleIcon

  return (
    <SvgIcon sx={{ width: size, height: size }}>
      <Flag />
    </SvgIcon>
  )
}

export default LanguageIcon
