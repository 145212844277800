import { useAuth0 } from '@auth0/auth0-react'
import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import { type UserProfile } from 'features/users'
import { queryClient } from 'services/react-query'
import { invalidateUserSettings } from './getUserSettings'
import { updateUserProfile, type UpdateUserProfileParams } from './updateUserProfile'

interface UpdateUserProfileVariables {
  params: UpdateUserProfileParams
}

interface UpdateUserProfileContext {
  previousProfile: UserProfile | null
}

/**
 * Mutation to update the user profile.
 */
export function useUpdateUserProfile (): UseMutationResult<UserProfile, Error, UpdateUserProfileVariables, UpdateUserProfileContext> {
  const { getAccessTokenSilently } = useAuth0()

  const mutationFn = async ({ params }: UpdateUserProfileVariables): Promise<UserProfile> => {
    const token = await getAccessTokenSilently()
    return await updateUserProfile(token, params)
  }

  const queryKey = ['userProfile']
  const mutationKey = ['updateUserProfile']

  return useMutation<
  UserProfile,
  Error,
  UpdateUserProfileVariables,
  UpdateUserProfileContext
  >({
    mutationKey,
    mutationFn,
    onMutate: async ({ params }: UpdateUserProfileVariables): Promise<UpdateUserProfileContext> => {
      // Cancel any outgoing refetch (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey, exact: true })

      // Snapshot the previous value
      const previousProfile = queryClient.getQueryData<UserProfile>(queryKey)

      // Optimistically update to the new value
      if (previousProfile != null) {
        const firstName = params.firstName ?? previousProfile.firstName
        const lastName = params.lastName ?? previousProfile.lastName
        const fullName = firstName !== null && lastName !== null
          ? `${firstName} ${lastName}`
          : null
        const orgName = params.orgName !== undefined ? params.orgName : previousProfile.orgName
        const optimisticProfile: UserProfile = {
          ...previousProfile,
          firstName,
          lastName,
          fullName,
          orgName,
          role: params.role !== undefined ? params.role : previousProfile.role,
          title: params.title !== undefined ? params.title : previousProfile.title,
          legalFieldsOfExpertise: params.legalFieldsOfExpertise !== undefined ? params.legalFieldsOfExpertise : previousProfile.legalFieldsOfExpertise,
          industriesOfExpertise: params.industriesOfExpertise !== undefined ? params.industriesOfExpertise : previousProfile.industriesOfExpertise,
          mainJurisdictions: params.mainJurisdictions !== undefined ? params.mainJurisdictions : previousProfile.mainJurisdictions,
          userPreferences: params.userPreferences !== undefined ? params.userPreferences : previousProfile.userPreferences
        }
        queryClient.setQueryData(queryKey, optimisticProfile)
      }

      return { previousProfile: previousProfile ?? null }
    },
    onError: (_err, _variables, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      if ((context?.previousProfile) != null) {
        queryClient.setQueryData(queryKey, context.previousProfile)
      }
    },
    onSettled: () => {
      // Always refetch after error or success to ensure cache is in sync with server
      void queryClient.invalidateQueries({ queryKey })

      // Also invalidate the user settings to ensure the anonymization rules are up to date.
      // For example, if the user changes their name, the anonymization rules are updated accordingly
      // by the backend (e.g. "John Doe" <-> "[PERSON_NAME_0]").
      void invalidateUserSettings(queryClient)
    }
  })
}
