import { useAuth0 } from '@auth0/auth0-react'
import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import { type Session } from 'features/sessions'
import { axiosInstance, createAuthHeaderFromToken } from 'services/axios'
import { queryClient } from 'services/react-query'

interface CreateSessionOptions {
  onSessionCreated: (sessionId: string | null) => void
}

/**
 * Hook to create a new session.
 *
 * It calls a callback function that we use to change the selected session.
 */
export function useCreateSession ({ onSessionCreated: changeSelectedSession }: CreateSessionOptions): UseMutationResult<Session, Error> {
  const { getAccessTokenSilently } = useAuth0()

  const mutationFn = async (): Promise<Session> => (
    await getAccessTokenSilently().then(async (token) =>
      await createNewSessionIfLastOneNotEmpty(token)
    )
  )

  return useMutation({
    mutationKey: ['createSession'],
    mutationFn,
    onError: (error, variables, context) => {
      console.error('Create session failed', error)
    },
    onSuccess: (session, variables, context) => {
      // Make an optimistic update to the cache
      queryClient.setQueryData<Session[]>(
        ['sessions'],
        (oldSessions) => (
          oldSessions !== undefined
            ? [session, ...oldSessions.filter((s) => s.id !== session.id)]
            : [session]
        )
      )

      // Invalidate any existing attachments cache
      void queryClient.invalidateQueries({
        queryKey: ['sessions', session.id, 'attachments'],
        exact: true
      })

      // Invalidate any existing messages cache
      void queryClient.invalidateQueries({
        queryKey: ['sessions', session.id, 'messages'],
        exact: true
      })

      // Set the selected session to the newly created one
      console.debug(`Selecting newly created session ${session.id}: ${session.title} `)
      changeSelectedSession(session.id)
    }
  })
}

const createNewSessionIfLastOneNotEmpty = async (token: string): Promise<Session> => {
  const authHeader = createAuthHeaderFromToken(token)
  const response = await axiosInstance.post<Session>(
    '/sessions',
    {
      createIfLastOneNotEmpty: true
    },
    {
      headers: authHeader,
      cache: false // Let react-query handle caching
    }
  )
  return response.data
}
