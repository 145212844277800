import { ArticleRounded, BalanceRounded, BorderColorRounded, ChatBubbleOutlineRounded, ContentCopyRounded, ContentPasteSearchRounded, Difference, DifferenceRounded, FindInPageRounded, FolderCopyRounded, GradingRounded, InsertPageBreakRounded, MailOutlineRounded, SearchRounded, TranslateRounded, ViewColumnRounded } from '@mui/icons-material'
import { type DocType } from 'features/documents'
import { AnalysisTaskAction, type UserInputForm } from 'features/user-input-form/types'
import getConfig from 'services/config'

export const draftIcon: JSX.Element = <BorderColorRounded />

export const docTypeToDraftIcon: Record<DocType, JSX.Element> = {
  Contract: <ArticleRounded />,
  Brief: <BalanceRounded />,
  Correspondence: <MailOutlineRounded />
}

export const draftBasedOnTemplateIcon: JSX.Element = <GradingRounded />

export const draftBasedOnCustomInstructionsIcon: JSX.Element = <ChatBubbleOutlineRounded />

export type TaskEntryLabel = 'Ask' | 'Analyze' | 'Compare' | 'Draft' | 'Summarize' | 'Search' | 'Translate' | 'Redact' | 'Templatize'

export interface TaskEntry {
  label: TaskEntryLabel
  icon: JSX.Element
  userInputForm: Partial<UserInputForm>
  disabled?: boolean
}

// For each task, describe them with a label, icon and partial user input form.
// The partial user input form is used to match the task to the provided user input form,
// and to update the user input form when the task is selected.
export const TASKS: TaskEntry[] = [
  {
    label: 'Ask',
    icon: <ChatBubbleOutlineRounded />,
    userInputForm: {
      mainTask: 'Chat',
      taskParams: undefined
    }
  },
  {
    label: 'Analyze',
    icon: <FindInPageRounded />,
    userInputForm: {
      mainTask: 'Analyze',
      taskParams: undefined
    }
  },
  {
    label: 'Compare',
    icon: <Difference />,
    userInputForm: {
      // Not yet implemented
      mainTask: undefined,
      taskParams: undefined
    },
    disabled: true
  },
  {
    label: 'Draft',
    icon: draftIcon,
    userInputForm: {
      mainTask: 'Draft',
      taskParams: undefined
    },
    disabled: getConfig('COPILEX_DRAFT_ENABLED') !== 'true'
  },
  {
    label: 'Summarize',
    icon: <InsertPageBreakRounded />,
    userInputForm: {
      mainTask: 'Summarize',
      taskParams: undefined
    }
  },
  {
    label: 'Translate',
    icon: <TranslateRounded />,
    userInputForm: {
      mainTask: 'Translate',
      taskParams: {
        sourceLang: 'all',
        targetLang: 'en'
      }
    }
  },
  {
    label: 'Redact',
    icon: <ArticleRounded />,
    userInputForm: {
      // Not yet implemented
      mainTask: undefined,
      taskParams: undefined
    },
    disabled: true
  },
  {
    label: 'Search',
    icon: <SearchRounded />,
    userInputForm: {
      mainTask: 'Search',
      taskParams: undefined
    },
    disabled: true
  },
  {
    label: 'Templatize',
    icon: <GradingRounded />,
    userInputForm: {
      mainTask: 'Templatize',
      taskParams: undefined
    },
    disabled: true
  }
]

// The same icon is currently used for all sub-tasks of the Analyze task
export const analyzeSubTasksIcon: JSX.Element = <ArticleRounded />

export const contractAnalysisIcon: JSX.Element = <ArticleRounded />

export const briefAnalysisIcon: JSX.Element = <BalanceRounded />

export const dataRoomIcon: JSX.Element = <FolderCopyRounded />

export const versionCompareIcon: JSX.Element = <DifferenceRounded />

export const comparativeAnalysisIcon: JSX.Element = <ContentCopyRounded />

export const analyzeTaskActionToIcon: Record<AnalysisTaskAction, JSX.Element> = {
  [AnalysisTaskAction.SanityCheck]: <FindInPageRounded />,
  [AnalysisTaskAction.ComparativeAnalysis]: <ContentCopyRounded />,
  [AnalysisTaskAction.SpecificAnalysis]: <ContentPasteSearchRounded />,
  [AnalysisTaskAction.SideBySideComparison]: <ViewColumnRounded />
}

export default TASKS
