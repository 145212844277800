import { Tooltip, useTheme } from '@mui/material'
import Icon from 'components/icons/feather'
import { type FC } from 'react'

interface Props {
  tooltip: string
}

export const HelpTooltipButton: FC<Props> = ({ tooltip }) => {
  const theme = useTheme()
  return <Tooltip title={tooltip}>
    <div>
      {/* Slightly smaller than a regular icon, to not standout too much */}
      <Icon.HelpCircle size={'0.75rem'} color={
        theme.palette.grey[600]
      } name="help" />
    </div>
  </Tooltip>
}

export default HelpTooltipButton
