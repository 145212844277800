import Loading from 'components/progress/Loading'
import { useSidepanes } from 'components/sidepanes'
import { useAuth } from 'context/AuthContext'
import { useGlobals } from 'context/GlobalsContext'
import { AttachmentsStack, FileUploader, useAttachments } from 'features/documents'
import { type ConfidentialityLevel } from 'features/globals/types'
import { isValidInput } from 'features/send-message/api/isValidInput'
import ConfidentialityToggleSwitchContainer from 'features/send-message/components/confidentiality-toggle-switch'
import SendButton from 'features/send-message/components/SendButton'
import { useSession } from 'features/sessions'
import { useUserInputForm, type UserInputForm } from 'features/user-input-form'
import { updateUserInputFormWithAttachments } from 'features/user-input-form/api/updateUserInputForm'
import type React from 'react'
import { useEffect, useState } from 'react'
import { AttachmentsContainer, BottomContainer, ComposeContainer, SendButtonContainer, TopContainer } from './Compose.styles'
import ComposeTextInput from './ComposeTextInput'
import UserPromptButtons from './UserPromptButtons'
import { isUploadInProgress } from 'features/documents/utils/uploadInprogress'
import UploadInProgress from 'features/documents/components/UploadInProgress'

interface ComposeProps {
  initInput: string
  processing: boolean
  onSubmit: (
    input: string,
    attachmentIds: string[],
    confidentialityLevel: ConfidentialityLevel,
    userInputForm: Partial<UserInputForm>
  ) => void
}

/**
 * Component to compose a message and send it to the assistant.
 */
export const Compose: React.FC<ComposeProps> = (
  { initInput, processing, onSubmit }: ComposeProps
) => {
  const globals = useGlobals()
  const currentUser = useAuth()
  const { selectedSession: session } = useSession()
  const {
    isPending: isPendingAttachment,
    isError: isErrorAttachment,
    data: attachments,
    error: errorAttachment
  } = useAttachments({ withContent: false, autoRefetch: false })
  const { userInputForm, setUserInputForm } = useUserInputForm()
  const { centralPane } = useSidepanes()

  const [input, setInput] = useState<string>(initInput)
  const [confidentialityLevel, setConfidentialityLevel] = useState<ConfidentialityLevel | null>(null)
  const [sendButtonIconOnly, setSendButtonIconOnly] = useState<boolean>(false)

  // Adjust the send button format to the width of the central pane
  useEffect(() => {
    setSendButtonIconOnly(centralPane.width < 600)
  }, [centralPane.width])

  // When session changes, fetch attachments and confidentiality level
  useEffect(() => {
    console.debug('> Compose [globals, currentUser, session]')

    if (session === null || session === undefined) return

    const level: number = session.settings?.confidentialityLevel ?? globals.defaultLevel.level
    const confidentialityLevel = globals.levels.find((l) => l.level === level)
    if (confidentialityLevel === undefined) throw new Error(`Confidentiality level ${level} not found!`)
    setConfidentialityLevel(confidentialityLevel)
  }, [globals, currentUser, session])

  // When attachments change, update the user input form
  useEffect(() => {
    console.debug('> Compose [attachments]')
    if (isPendingAttachment || isErrorAttachment || attachments === undefined) return

    setUserInputForm(prevForm => (
      updateUserInputFormWithAttachments(prevForm, attachments.uploaded)
    ))
  }, [attachments])

  useEffect(() => {
    console.debug('> Compose [initInput]')

    setInput(initInput)
  }, [initInput])

  if (session === null || confidentialityLevel === null || isPendingAttachment) {
    return <Loading />
  }

  if (isErrorAttachment) {
    throw errorAttachment
  }

  const mayReview = !confidentialityLevel.neverReview
  const [isValid, validationMessage] = isValidInput(input, userInputForm, attachments)

  const handleSubmit = (): void => {
    if (!isValid) return

    const attachmentIds = attachments.uploaded.map((a) => a.id)

    onSubmit(input, attachmentIds, confidentialityLevel, userInputForm)
    // setInput('');  // Clear input
  }

  return (
    <ComposeContainer className="compose-container">
      <TopContainer className="compose-top-container">
        <UserPromptButtons />
      </TopContainer>

      <ComposeTextInput
        userInputForm={userInputForm}
        attachments={attachments}
        input={input}
        setInput={setInput}
        processing={processing}
        onSubmit={handleSubmit}
      />

      <BottomContainer className="compose-bottom-container">
        <AttachmentsContainer className="compose-attachments-container">
          <FileUploader iconOnly />
          <AttachmentsStack attachments={attachments} />
        </AttachmentsContainer>
        <SendButtonContainer>
          <ConfidentialityToggleSwitchContainer />
          <SendButton
            onClick={handleSubmit}
            mayReview={mayReview}
            disabled={!isValid || processing}
            processing={processing}
            iconOnly={sendButtonIconOnly}
            validationMessage={validationMessage}
          />
        </SendButtonContainer>
      </BottomContainer>
    </ComposeContainer>
  )
}
