import DataObjectIcon from '@mui/icons-material/DataObject'
import {
  Tooltip,
  IconButton,
  useTheme
} from '@mui/material'
import { useIntl } from 'react-intl'
import type React from 'react'
import iconButtonSx from 'components/buttons/ToggleIconButton.styles'

interface Props {
  showState: boolean
  setShowState: (showState: boolean) => void
}

const ToggleStateButton: React.FC<Props> = ({ showState, setShowState }: Props) => {
  const intl = useIntl()
  const theme = useTheme()
  const title = (
    showState
      ? intl.formatMessage({
        id: 'app.toggle-state-button.enable-tooltip',
        defaultMessage: '(Dev mode) Show the agent internal state'
      })
      : intl.formatMessage({
        id: 'app.toggle-state-button.disable-tooltip',
        defaultMessage: '(Dev mode) Hide the agent internal state'
      })
  )
  return <Tooltip title={title}>
    <IconButton
      onClick={() => { setShowState(!showState) }}
      size='small'
      sx={iconButtonSx(showState, theme.palette)}
    >
      <DataObjectIcon color={'inherit'} />
    </IconButton>
  </Tooltip>
}

export default ToggleStateButton
