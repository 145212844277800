import { type AxiosError } from 'axios'
import { axiosInstance, createAuthHeaderFromToken } from 'services/axios'
import { type UserProfile } from 'features/users'
import { type AreaOfLaw, type Industry, type Jurisdiction } from 'features/globals'

export interface UpdateUserProfileParams {
  firstName?: string | null
  lastName?: string | null
  orgName?: string | null
  role?: string | null
  title?: string | null
  legalFieldsOfExpertise?: AreaOfLaw[] | null
  industriesOfExpertise?: Industry[] | null
  mainJurisdictions?: Jurisdiction[] | null
  userPreferences?: string | null
}

export const updateUserProfile = async (
  token: string,
  params: UpdateUserProfileParams
): Promise<UserProfile> => {
  try {
    const response = await axiosInstance.patch<UserProfile>(
      '/users/me',
      {
        first_name: params.firstName,
        last_name: params.lastName,
        org_name: params.orgName,
        role: params.role,
        title: params.title,
        legal_fields_of_expertise: params.legalFieldsOfExpertise,
        industries_of_expertise: params.industriesOfExpertise,
        main_jurisdictions: params.mainJurisdictions,
        user_preferences: params.userPreferences
      },
      {
        headers: createAuthHeaderFromToken(token),
        cache: {
          update: {
            'get-user-profile': 'delete'
          }
        }
      }
    )

    return {
      ...response.data,
      fullName: response.data.firstName !== null && response.data.lastName !== null
        ? `${response.data.firstName} ${response.data.lastName}`
        : null
    }
  } catch (err: unknown) {
    const error = err as AxiosError
    throw error
  }
}

export default updateUserProfile
