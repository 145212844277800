import { useDeleteAttachment } from 'features/documents/api/deleteAttachment'
import { defaultFileIconSrc, getFileIconSrc } from 'features/documents/components/FiletypeIcon'
import { useMemo, type FC } from 'react'
import { useIntl } from 'react-intl'
import { splitFilenameExtension } from 'services/utils'
import DocumentCard from './DocumentCard'

interface Props {
  attachmentId: string
  filename: string
  createdAt: string
  isDeleted: boolean
  onSelect: () => void
}

export const DocumentCardContainer: FC<Props> = ({
  attachmentId,
  filename,
  createdAt,
  isDeleted,
  onSelect
}: Props): JSX.Element => {
  const intl = useIntl()
  const deleteAttachmentMutation = useDeleteAttachment({ attachmentId })
  const [nameWithoutExtension, extension] = splitFilenameExtension(filename)
  const fileIconSrc = extension !== null ? getFileIconSrc(extension) : defaultFileIconSrc

  const confirmationMsg = useMemo(() => intl.formatMessage({
    id: 'app.attachment-chip.confirmation-msg',
    defaultMessage: 'Are you sure you want to delete this attachment?'
  }), [intl])

  const handleDelete = (): void => {
    if (window.confirm(confirmationMsg)) {
      deleteAttachmentMutation.mutate()
    }
  }

  return (
    <DocumentCard
      fileIconSrc={fileIconSrc}
      nameWithoutExtension={nameWithoutExtension}
      createdAt={createdAt}
      onSelect={onSelect}
      onDelete={!isDeleted ? handleDelete : undefined}
    />
  )
}

export default DocumentCardContainer
