import { Delete, Warning } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { FloatingViewIcon } from 'components/icons/FloatingViewIcon'
import { DOC_TYPE_NOT_CERTAIN, type AttachmentListItem } from 'features/documents/types'
import { useHovered } from 'hooks/useHovered'
import { useIntl } from 'react-intl'
import { DocTypeTypography, FilenameTypography, LabelContainer, StyledChip } from './AttachmentChip.styles'
import { useMemo } from 'react'

interface Props {
  attachment: AttachmentListItem
  /**
   * If true, the document type will be displayed inside the label.
   * Otherwise, the document type will be displayed only in the tooltip.
   */
  docTypeInLabel: boolean
  /**
   * If true, the attachment will be deletable via a button in the chip.
   */
  deletable: boolean
  /**
   * If true, will open the attachment in the document panel when clicked.
   */
  openable: boolean
  /**
   * Callback function to be called when the attachment is deleted.
   */
  onDelete?: () => void
  /**
   * Callback function to be called when the attachment is opened.
   */
  onOpen?: () => void
}

/**
 * Presentational component for the attachment chip.
 */
export const AttachmentChip = ({ attachment, deletable, docTypeInLabel, openable, onDelete, onOpen }: Props): JSX.Element => {
  const intl = useIntl()
  const { isHovered: isLabelHovered, containerProps: hoveredContainerProps } = useHovered()

  const confirmationMsg = useMemo(() => intl.formatMessage({
    id: 'app.attachment-chip.confirmation-msg',
    defaultMessage: 'Are you sure you want to delete this attachment?'
  }), [intl])

  const handleDelete = (): void => {
    if (deletable && onDelete !== undefined) {
      if (window.confirm(confirmationMsg)) {
        onDelete()
      }
    }
  }

  const handleClick = (): void => {
    if (openable && onOpen !== undefined) {
      onOpen()
    }
  }

  const translateDocType = (docType: string): string => (
    intl.formatMessage({
      id: `app.doc-type.${docType}`,
      defaultMessage: docType
    })
  )

  const docTypeDescription: string =
    (
      attachment.docType === undefined ||
      attachment.docType === DOC_TYPE_NOT_CERTAIN
    )
      ? ''
      : translateDocType(attachment.docType)

  const viewTooltip = useMemo(() => intl.formatMessage({
    id: 'app.attachment-chip.view-tooltip',
    defaultMessage: 'Click to view attachment'
  }), [intl])

  const viewIcon = openable && isLabelHovered && (
    <FloatingViewIcon />
  )

  // const title: string = `Attached file ${attachment.filename}
  //   ${attachment.truncated
  //     ? ' (File too big, only part of it will be used)'
  //     : ''}`

  const truncatedMsg = useMemo(() => intl.formatMessage({
    id: 'app.attachment-chip.truncated',
    defaultMessage: 'File too big, only part of it will be used'
  }), [intl])

  const fileDesc = intl.formatMessage({
    id: 'app.attachment-chip.title',
    defaultMessage: 'Attached file: {filename}\nDocument type: {docType}'
  }, {
    filename: attachment.filename,
    docType: docTypeDescription !== '' ? docTypeDescription : translateDocType('unknown')
  })

  const title = fileDesc + (attachment.truncated ? ` (${truncatedMsg})` : '') + (openable ? `\n${viewTooltip}` : '')

  const label = (
    <LabelContainer
      {...hoveredContainerProps}
    >
      <Tooltip
        key={attachment.id}
        title={<span style={{ whiteSpace: 'pre-line' }}>{title}</span>}
      >
        <FilenameTypography>{attachment.filename}</FilenameTypography>
      </Tooltip>
      {docTypeInLabel && <DocTypeTypography>{docTypeDescription !== '' ? ` (${docTypeDescription})` : ''}</DocTypeTypography>}
      {viewIcon !== undefined && viewIcon}
    </LabelContainer>
  )

  const deleteTooltip = useMemo(() => intl.formatMessage({
    id: 'app.attachment-chip.delete-tooltip',
    defaultMessage: 'Delete attachment'
  }), [intl])
  const deleteIcon = (
    <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{deleteTooltip}</span>}>
      <Delete />
    </Tooltip>
  )

  const icon = attachment.truncated
    ? <Warning sx={{ opacity: 0.5 }} />
    : undefined

  return (
    <StyledChip
      label={label}
      onDelete={deletable ? handleDelete : undefined}
      deleteIcon={deleteIcon}
      icon={icon}
      onClick={handleClick}
      clickable={openable}
    />
  )
}

export default AttachmentChip
