import { useAuth0 } from '@auth0/auth0-react'
import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import { axiosInstance, createAuthHeaderFromToken } from 'services/axios'
import { queryClient } from 'services/react-query'
import { type Session } from '../types'

interface DeleteSessionContext {
  previousSessions: Session[]
}

export function useDeleteSession (): UseMutationResult<void, Error, string, DeleteSessionContext> {
  const { getAccessTokenSilently } = useAuth0()

  const mutationFn = async (sessionId: string): Promise<void> => {
    await getAccessTokenSilently().then(async (token) => {
      await deleteSession(token, sessionId)
    })
  }

  return useMutation({
    mutationKey: ['deleteSession'],
    mutationFn,
    onMutate: async (sessionId) => {
      // Cancel any outgoing refetch (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ['sessions'], exact: true })

      // Invalidate any existing messages cache
      void queryClient.invalidateQueries({
        queryKey: ['sessions', sessionId, 'messages'],
        exact: true
      })

      // Invalidate any existing attachments cache
      void queryClient.invalidateQueries({
        queryKey: ['sessions', sessionId, 'attachments'],
        exact: true
      })

      // Snapshot the previous value
      const sessions = queryClient.getQueryData(['sessions']) as Session[]

      // Optimistically update to the new value
      const updatedSessions = sessions.filter((session) => session.id !== sessionId)
      queryClient.setQueryData(['sessions'], updatedSessions)

      // Return a context object with the snapshot value
      return { previousSessions: sessions }
    },
    onError: (error, sessionId, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      console.error(`Delete session ${sessionId} failed`, error)
      if (context === undefined) {
        return
      }
      queryClient.setQueryData(['sessions'], context.previousSessions)
    },
    onSettled: (data, sessionId, _) => {
      // Always refetch after error or success
      void queryClient.invalidateQueries({
        queryKey: ['sessions'],
        exact: true
      })
    }
  })
}

const deleteSession = async (
  token: string,
  sessionId: string
): Promise<void> => {
  const authHeader = createAuthHeaderFromToken(token)
  await axiosInstance.delete(
    `/sessions/${sessionId}`,
    {
      headers: authHeader,
      cache: false // Let react-query handle caching
    }
  )
}
