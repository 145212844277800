import { useAuth0 } from '@auth0/auth0-react'
import { Close } from '@mui/icons-material'
import {
  IconButton,
  Tooltip
} from '@mui/material'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'

/**
 * Button to go back to the login or signup page.
 * Useful to leave the callback page if an error occurs.
 */
export const BackToAuthButton = (): JSX.Element => {
  const intl = useIntl()
  const { logout } = useAuth0()

  const handleClick = (): void => {
    const returnTo = window.location.origin + '/login'
    console.debug('Logging out and going back to', returnTo)
    void logout({
      logoutParams: {
        returnTo
      }
    })
  }

  const tooltip = useMemo(() => intl.formatMessage({
    id: 'app.back-to-auth-button.tooltip',
    defaultMessage: 'Logout and go back to login or sign up page'
  }), [intl])

  return (
    <Tooltip title={tooltip}>
      <IconButton
        onClick={handleClick}
      >
        <Close />
      </IconButton>
    </Tooltip>
  )
}

export default BackToAuthButton
