// Context provider for constants provided by the API /globals route
import { type ReactNode, createContext, useEffect, useState, useContext, useMemo, useCallback } from 'react'
import { getGlobals } from 'features/globals/api/getGlobals'
import { type Globals } from 'features/globals/types'
import { useAuth0 } from '@auth0/auth0-react'

interface GlobalsContextValue {
  globals: Globals | null
  isLoading: boolean
  error: Error | null
}

const initialContextValue: GlobalsContextValue = {
  globals: null,
  isLoading: true,
  error: null
}

export const GlobalsContext = createContext<GlobalsContextValue>(initialContextValue)

interface Props {
  children: ReactNode
}

/**
 * Fetches and provides global constants from the API
 */
export const GlobalsProvider = ({ children }: Props): JSX.Element => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()
  const [globals, setGlobals] = useState<Globals | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | null>(null)

  const fetchGlobals = useCallback(async (): Promise<void> => {
    try {
      setIsLoading(true)
      setError(null)
      const token = await getAccessTokenSilently()
      const data = await getGlobals(token)
      setGlobals(data)
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to fetch globals'))
      console.error('Error fetching globals:', err)
    } finally {
      setIsLoading(false)
    }
  }, [getAccessTokenSilently])

  useEffect(() => {
    if (isAuthenticated) {
      void fetchGlobals()
    } else {
      setGlobals(null)
      setIsLoading(false)
      setError(null)
    }
  }, [isAuthenticated, fetchGlobals])

  const contextValue = useMemo(() => ({
    globals,
    isLoading,
    error
  }), [globals, isLoading, error])

  return (
    <GlobalsContext.Provider value={contextValue}>
      {!isLoading && globals !== null ? children : null}
    </GlobalsContext.Provider>
  )
}

export const useGlobals = (): Globals => {
  const context = useContext(GlobalsContext)

  if (context === null) {
    throw new Error('useGlobals must be used within a GlobalsProvider')
  }

  if (context.globals === null) {
    throw new Error('Globals are not yet loaded')
  }

  return context.globals
}
