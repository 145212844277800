import { type User } from 'context/AuthContext'
import { axiosInstance, createAuthHeaderFromToken, defaultTTL } from 'services/axios'
import { type UserSettings } from '../types'
import { useQuery, useQueryClient, type UseQueryResult, type QueryClient } from '@tanstack/react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { useAuth } from 'context/AuthContext'

export const getUserSettings = async (
  token: string,
  user: User
): Promise<UserSettings> => {
  const response = await axiosInstance.get<UserSettings>(
    '/users/me/settings',
    {
      headers: createAuthHeaderFromToken(token),
      id: 'get-user-settings',
      cache: {
        ttl: defaultTTL
      }
    }
  )
  return response.data
}

export const useUserSettings = (): UseQueryResult<UserSettings | null, Error> => {
  const currentUser = useAuth()
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()

  return useQuery({
    queryKey: ['userSettings'],
    queryFn: async (): Promise<UserSettings | null> => {
      if (!isAuthenticated || currentUser === null) return null
      const token = await getAccessTokenSilently()
      return await getUserSettings(token, currentUser)
    },
    enabled: isAuthenticated && currentUser !== null,
    staleTime: 5 * 60 * 1000 // 5 minutes
  })
}

/**
 * Invalidates the userSettings query, forcing a refetch on the next render
 * @param queryClient The QueryClient instance
 */
export const invalidateUserSettings = async (queryClient: QueryClient): Promise<void> => {
  console.log('Invalidating user settings')
  // refetchType: 'all' is used to force a refetch of the query, even if the data is still fresh
  await queryClient.invalidateQueries({ queryKey: ['userSettings'], refetchType: 'all' })
  console.log('User settings invalidated')
}
