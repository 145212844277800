import { IconButton, Tooltip } from '@mui/material'
import { EditRounded, ThumbDownAltRounded, ThumbUpAltRounded } from '@mui/icons-material'
import { type CSSProperties, useMemo } from 'react'
import { useIntl } from 'react-intl'

interface Props {
  feedback: number
  onClick: () => void
}

const enabledFill: CSSProperties = { fill: 'currentColor' }
const disabledFill: CSSProperties = { fill: 'gray' }

export const ThumbsUpButton: React.FC<Props> = ({ feedback, onClick }: Props): JSX.Element => {
  return <Tooltip title="Helpful answer">
    <IconButton onClick={onClick} size='small'>
      {feedback === 1 ? <ThumbUpAltRounded style={enabledFill} /> : <ThumbUpAltRounded style={disabledFill} />}
    </IconButton>
  </Tooltip>
}

export const ThumbsDownButton: React.FC<Props> = ({ feedback, onClick }: Props): JSX.Element => {
  return <Tooltip title="Not helpful">
    <IconButton onClick={onClick} size='small'>
      {feedback === -1 ? <ThumbDownAltRounded style={enabledFill} /> : <ThumbDownAltRounded style={disabledFill} />}
    </IconButton>
  </Tooltip>
}

interface EditButtonProps {
  disabled?: boolean
  previouslyEdited: boolean
  onClick: () => void
}

export const EditButton: React.FC<EditButtonProps> = (
  { disabled = false, previouslyEdited, onClick }: EditButtonProps
): JSX.Element => {
  const intl = useIntl()

  const title = useMemo(() => intl.formatMessage({
    id: 'app.edit-button.label',
    defaultMessage: 'Suggest a correction'
  }), [intl])

  return <Tooltip title={title}>
    <span>
      <IconButton disabled={disabled} onClick={onClick} size='small'>
        {previouslyEdited ? <EditRounded style={enabledFill} /> : <EditRounded style={disabledFill} />}
      </IconButton>
    </span>
  </Tooltip>
}
