import { type DocReviewResults } from 'features/analyze/types'
import { type Entity } from 'features/anonymization/types'
import { type AssistantSettings } from 'features/assistants/types'
import { type UserInputForm } from 'features/user-input-form/types'

export interface SessionHistory {
  history: Message[]
  lastUserInputForm: UserInputForm | null
}

export enum Role {
  ASSISTANT = 'assistant',
  USER = 'user'
}

export interface Source {
  url: string
  title: string
  snippet: string
}

export interface MasterAgentState {
  userInputForm?: UserInputForm
  docReviewResults?: DocReviewResults
  reformulatedRequest?: string
  sources?: Source[] | null
}

export interface Message {
  idx?: number
  role: Role
  cleartextContent: string
  anonContent: string
  existingEntities?: Entity[]
  feedback?: number
  correction?: string
  settings?: AssistantSettings
  state?: MasterAgentState
  /**
   * De-anonymized version of the state.
   * This is only used to display in the UI information
   * that is in the state, such as document review results.
   */
  deanonState?: MasterAgentState
}
