import { Box, Typography } from '@mui/material'
import { findTaskEntryForMainTask, TASKS, type TaskEntry } from 'components/central-pane/task-select'
import { AttachmentsStack } from 'features/documents'
import { SummarizeFormDigest } from 'features/summarize'
import { AnalysisTaskAction, type AnalysisTaskParams, type SummarizeTaskParams, type TranslateTaskParams, type UserInputForm } from 'features/user-input-form'
import { useIntl } from 'react-intl'

interface Props {
  userInputForm: UserInputForm
}

const rowSx = {
  // Allow shrinking if needed
  minWidth: 0,
  flexShrink: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 1,
  flexWrap: 'wrap'
}

/**
 * Display a digest of the user input form for a user message,
 * e.g., what was the selected task and what attachments were uploaded
 * at the time of the message.
 */
export const UserInputFormDigest: React.FC<Props> = ({ userInputForm }: Props) => {
  const intl = useIntl()

  const addAnalyzeSpecificTaskComponents = (
    taskParams: AnalysisTaskParams,
    taskComponents: JSX.Element[]
  ): void => {
    taskComponents.push(
      <Typography key={2}>
        {' > '}
        {
          intl.formatMessage({
            id: `app.analyze-task.contract-analysis.action.${taskParams.action}`,
            defaultMessage: taskParams.action
          })
        }
      </Typography>
    )
    if (
      taskParams.action === AnalysisTaskAction.SanityCheck &&
      taskParams.sanityCheckOptions !== undefined
    ) {
      // List of sanity check options to choose from
      const sanityCheckOptionsComponent = (
        <Typography key={3}>
          {' > ['}
          {
            taskParams.sanityCheckOptions.map((option) =>
              intl.formatMessage({
                id: `app.doc-review.sanity-check.options.${option}`,
                defaultMessage: option
              })
            ).join(', ')
          }
          {']'}
        </Typography>
      )
      taskComponents.push(sanityCheckOptionsComponent)
    } else if (
      taskParams.action === AnalysisTaskAction.ComparativeAnalysis &&
      taskParams.comparativeAnalysisOptions !== undefined &&
      taskParams.comparativeAnalysisOptions.comparisonTypeId !== null
    ) {
      // There is only one option for comparative analysis right now
      const comparisonTypeId = taskParams.comparativeAnalysisOptions.comparisonTypeId
      // In some old messages, the comparisonTypeId may not always be set
      if (comparisonTypeId !== undefined && comparisonTypeId !== null) {
        taskComponents.push(
          <Typography key={3}>
            {
              ' > '
            }
            {
              intl.formatMessage({
                id: `app.comparative-analysis.type-select.options.${comparisonTypeId}`,
                defaultMessage: comparisonTypeId
              })
            }
          </Typography>
        )
      }
    } else if (
      taskParams.action === AnalysisTaskAction.SpecificAnalysis &&
      taskParams.specificAnalysisOptions !== undefined
    ) {
      // List of sanity check options to choose from
      const specificAnalysisOptionsComponent = (
        <Typography key={3}>
          {' > ['}
          {
            taskParams.specificAnalysisOptions.map((option) =>
              intl.formatMessage({
                id: `app.specific-analysis-options-select.options.${option}`,
                defaultMessage: option
              })
            ).join(', ')
          }
          {']'}
        </Typography>
      )
      taskComponents.push(specificAnalysisOptionsComponent)
    }
  }

  const addTranslateSpecificTaskComponents = (
    taskParams: TranslateTaskParams,
    taskComponents: JSX.Element[]
  ): void => {
    const { sourceLang, targetLang } = taskParams
    taskComponents.push(
      <Box key={2} sx={{ display: 'flex', gap: 1 }}>
        <Typography>
          {
            intl.formatMessage({
              id: 'app.translate-task-language-select.from.label',
              defaultMessage: 'From'
            })
          }
        </Typography>
        <Typography>
          {
            intl.formatMessage({
              id: `app.translate-task-language-select.lang-${sourceLang}.label`,
              defaultMessage: sourceLang
            })
          }
        </Typography>
        <Typography>
        {
            intl.formatMessage({
              id: 'app.translate-task-language-select.to.label',
              defaultMessage: 'to'
            })
          }
        </Typography>
        <Typography>
          {
            intl.formatMessage({
              id: `app.translate-task-language-select.lang-${targetLang}.label`,
              defaultMessage: targetLang
            })
          }
        </Typography>
      </Box>
    )
  }

  const getTranslatedTaskLabel = (userInputForm: UserInputForm): string | undefined => {
    // There is a special case for Analyze: we want to display to sub-task instead of the main one.
    // For now this is always "Contract analysis" as the others are not enabled yet.
    if (userInputForm.mainTask === 'Analyze') {
      return intl.formatMessage({
        id: 'app.analyze-task.sub-task.Contract analysis',
        defaultMessage: 'Contract analysis'
      })
    } else if (userInputForm.mainTask !== undefined && userInputForm.mainTask !== null) {
      const label = findTaskEntryForMainTask(userInputForm.mainTask).label
      return intl.formatMessage({
        id: `app.main-task.${label}`,
        defaultMessage: label
      })
    } else {
      return undefined
    }
  }

  const addSummarizeSpecificTaskComponents = (
    taskParams: SummarizeTaskParams,
    taskComponents: JSX.Element[]
  ): void => {
    taskComponents.push(
      <SummarizeFormDigest key={3} taskParams={taskParams} />
    )
  }

  // Construct a list of components to render for the "Task" part
  const taskComponents: JSX.Element[] = []
  if (userInputForm.mainTask !== undefined && userInputForm.mainTask !== null) {
    const task: TaskEntry | undefined = TASKS.find((task) => task.userInputForm.mainTask === userInputForm.mainTask)
    if (task !== undefined) {
      taskComponents.push(
        <Box key={0} sx={rowSx}>
          {task.icon}
        </Box>
      )
      const translatedTaskLabel = getTranslatedTaskLabel(userInputForm) as string
      taskComponents.push(
        <Typography key={1}>
          {translatedTaskLabel}
        </Typography>
      )

      // Display task-specific options
      if (userInputForm.taskParams !== undefined && userInputForm.taskParams !== null) {
        if (userInputForm.mainTask === 'Analyze') {
          addAnalyzeSpecificTaskComponents(userInputForm.taskParams as AnalysisTaskParams, taskComponents)
        } else if (userInputForm.mainTask === 'Translate') {
          addTranslateSpecificTaskComponents(userInputForm.taskParams as TranslateTaskParams, taskComponents)
        } else if (userInputForm.mainTask === 'Summarize') {
          addSummarizeSpecificTaskComponents(userInputForm.taskParams as SummarizeTaskParams, taskComponents)
        }
      }
    }
  }

  return (
    <Box className='user-input-form-digest' sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box sx={rowSx}>
        <Typography variant="caption" color="text.secondary">
          {
            intl.formatMessage({
              id: 'app.user-input-form-digest.task',
              defaultMessage: 'Task:'
            })
          }
        </Typography>
        {taskComponents}
      </Box>
      <Box sx={rowSx}>
        {
          userInputForm.attachments.length > 0 && (
            <>
              <Typography variant="caption" color="text.secondary">
                {
                  intl.formatMessage({
                    id: 'app.user-input-form-digest.attachments',
                    defaultMessage: 'Attachments:'
                  })
                }
              </Typography>
              <AttachmentsStack
                attachments={{
                  uploaded: userInputForm.attachments,
                  pending: [],
                  deleted: []
                }}
                deletable={false}
                openable={true}
              />
            </>
          )
        }
      </Box>
    </Box>
  )
}

export default UserInputFormDigest
