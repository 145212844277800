import { Box, CircularProgress, Typography } from '@mui/material'
import CancelButton from 'components/buttons/CancelButton'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { BackdropContainer, StyledBackdrop, StyledDivider, bottomContainerSx } from './ProgressBackdrop.styles'

interface Props {
  open: boolean
  msg?: string
  onCancel?: () => void
}

/**
 * Presentational component for the progress backdrop.
 */
const ProgressBackdrop = ({ open, msg, onCancel }: Props): JSX.Element => {
  const intl = useIntl()

  const title = useMemo(() => intl.formatMessage({
    id: 'app.progress-backdrop.message',
    defaultMessage: 'Please wait while Copilex is processing your request.'
  }), [intl])
  const message = useMemo(() => intl.formatMessage({
    id: 'app.progress-backdrop.sub-message',
    defaultMessage: '(This may take a few minutes)'
  }), [intl])

  return (
    <StyledBackdrop open={open}>
      <BackdropContainer>
        <Typography variant="h5" align="center">
          {title}
        </Typography>
        <Typography variant="h6">
          {message}
        </Typography>
        <CircularProgress />
        {/* Optional message to display to the user */}
        {
          msg !== undefined &&
          <Box sx={bottomContainerSx}>
            <StyledDivider />
            <Typography variant="h6">
              {msg}
            </Typography>
          </Box>
        }
        {
          onCancel !== undefined &&
          <CancelButton onClick={onCancel} />
        }
      </BackdropContainer>
    </StyledBackdrop>
  )
}

export default ProgressBackdrop
